import {AbbreviateLargeNumber} from 'common/app/utilities/NumberUtility';
import {
	isRulesBasedModelMode,
	getQueryProperty,
	setPublicProperty,
	setEntitiesProperty,
	setResourceTypeCount,
	setRestrictions,
	dispatchQueryPropertyValue,
	setQueryPropertyValue,
} from 'common/stores/query';
import type {QueryState, RestrictionType} from 'common/stores/query/types';
import {getDataCloudProperty} from 'common/stores/datacloud';
import {
	isCalculateKnownContactEnabled,
	isSegmentationV2Enabled,
	isTimeSeriesSegmentEnabled,
} from 'common/stores/tenantConfig';
import UserValues from 'common/widgets/utilities/user-values.utility';
import {getSessionSegmentState} from 'common/components/datacloud/query/results/rebuild/segment.helpers';
import {ISegment} from '../../../../atlas/app/data/SegmentConst';
import {validResourceTypes, entities} from './query.constants';
import type {
	Bkt,
	Restrictions,
	Restriction,
	QueryAttribute,
	LogicalRestriction,
	LogicalRestrictionRecord,
} from './query.types';
import {updateBucketCount} from './advanced/tree/tree.helpers';
import type {Query, BucketToRuleMap} from '../datacloud.types';
import {
	EntitiesCountsResponse,
	getDataByQuery as fetchDataByQuery,
	getEntitiesCounts as getEntitiesCountsQuery,
} from './queryService.queries';
import {
	sanitizeSegment,
	flattenSegmentRestrictions,
} from '../segment/segment.helpers';
import {Attribute, AttributeEntity} from './advanced/tree/types';
import {getBucketRestriction} from './queryBuilder.utilities';
import {Entity, Operator} from './query.enums';
import {getSegmentByName} from '../segment/segment.queries';
import {SegmentCreate} from '../segment/SegmentQueryHelper';

const isValidResourceType = (resourceType: string): boolean =>
	validResourceTypes.includes(resourceType);

const getAllActivityBuckets = (
	tree: Restrictions,
	restrictions: Restrictions = []
): Restrictions => {
	return tree.reduce(
		(activityRestrictions, branch) => {
			if (
				branch?.bucketRestriction?.bkt ||
				branch?.segmentMemberRestriction?.segmentName ||
				branch?.activityRestriction
			) {
				return [...activityRestrictions, branch];
			}

			if (branch?.logicalRestriction) {
				const logicalRestrictions = getAllActivityBuckets(
					branch.logicalRestriction.restrictions,
					activityRestrictions
				);

				return [...logicalRestrictions];
			}

			return [...activityRestrictions];
		},
		[...restrictions]
	);
};

const updateAllBucketCounts = async (
	bucketRestrictions: Restrictions
): Promise<string> => {
	for (const restriction of bucketRestrictions) {
		if (getQueryProperty('cancelUpdateBucketCalls') === true) {
			setPublicProperty('disableAllTreeRestrictions', false);

			throw new Error('Bucket count update was canceled.');
		}

		if (restriction.bucketRestriction) {
			/**
			 * Well, the previous version has an await for every
			 * loop, sooooo I guess I will leave it that way...
			 */
			// eslint-disable-next-line no-await-in-loop
			const data = await updateBucketCount({...restriction.bucketRestriction});

			if (typeof data === 'number' && restriction.bucketRestriction.bkt) {
				restriction.bucketRestriction.bkt.Cnt = data;
			}
		}
	}

	setPublicProperty('disableAllTreeRestrictions', false);

	return 'Bucket count update was completed.';
};

// #region setResourceType
interface GetDataByQueryErrorResponse {
	error: {
		errMsg: string;
	};
}

const getDataByQuery = (
	resourceType: string,
	query: Query
): Promise<Query | GetDataByQueryErrorResponse> => {
	if (!isValidResourceType(resourceType)) {
		return new Promise((resolve) => {
			resolve({
				error: {
					errMsg: `Invalid resourceType: ${resourceType}`,
				},
			});
		});
	}

	const queryWithRestriction: Query = {
		free_form_text_search: query.free_form_text_search,
		account_restriction: query.account_restriction,
		contact_restriction: query.contact_restriction,
		preexisting_segment_name: query.preexisting_segment_name,
		page_filter: {
			num_rows: query.page_filter?.num_rows || 0,
			row_offset: query.page_filter?.row_offset || 0,
		},
		restrict_with_sfdcid: query.restrict_with_sfdcid,
	};

	if (resourceType === 'accounts') {
		queryWithRestriction.lookups = query.lookups;
	}

	if (query.sort) {
		queryWithRestriction.sort = query.sort;
	}

	return new Promise((resolve) => {
		resolve(fetchDataByQuery(resourceType, queryWithRestriction));
	});
};

const setAccounts = (
	query: Query
): Promise<Query | GetDataByQueryErrorResponse> =>
	getDataByQuery('accounts', query).then((response) => response);

const setContacts = (
	query: Query
): Promise<Query | GetDataByQueryErrorResponse> =>
	getDataByQuery('contacts', query).then((response) => response);
// #endregion setResourceType

const generateBucketLabel = (bkt: Bkt): Bkt => {
	const abbrs = ['K', 'M', 'B'];

	const a = bkt.Vals
		? AbbreviateLargeNumber(bkt.Vals[0] as number, 0, abbrs)
		: undefined;
	const b = bkt.Vals
		? AbbreviateLargeNumber(bkt.Vals[1] as number, 0, abbrs)
		: undefined;

	switch (bkt.Cmp) {
		case 'Yes':
			return {
				...bkt,
				Lbl: 'Yes',
			};
		case 'empty':
		case 'IS_NULL':
		case 'IS_NOT_NULL':
			return {...bkt, Lbl: ''};
		case 'EQUAL':
			return {
				...bkt,
				Lbl: bkt.Vals ? (bkt.Vals[0] as string) : '',
			};
		case 'GREATER_THAN':
			return {...bkt, Lbl: `> ${a}`};
		case 'LESS_THAN':
			return {...bkt, Lbl: `< ${a}`};
		case 'GREATER_OR_EQUAL':
			return {...bkt, Lbl: `>= ${a}`};
		case 'LESS_OR_EQUAL':
			return {...bkt, Lbl: `<= ${a}`};
		case 'GT_AND_LTE':
			return {...bkt, Lbl: `> ${a} and <= ${b}`};
		case 'GT_AND_LT':
			return {...bkt, Lbl: `> ${a} and < ${b}`};
		case 'GTE_AND_LTE':
			return {...bkt, Lbl: `>= ${a} and <= ${b}`};
		case 'GTE_AND_LT':
		case 'between':
		case 'BETWEEN':
			return {...bkt, Lbl: `${a} - ${b}`};
		case 'IN_COLLECTION':
			return {...bkt, Lbl: bkt.Vals ? bkt.Vals.join(', ') : ''};
		case 'NOT_IN_COLLECTION':
			return {...bkt, Lbl: bkt.Vals ? `not ${bkt.Vals.join(', ')}` : ''};
		default: {
			const cmpLabel =
				bkt.Vals && bkt.Vals.length > 0 ? (a as string) : 'empty';

			return {
				...bkt,
				Lbl: bkt.Cmp ? cmpLabel : '',
			};
		}
	}
};

const getAllBuckets = (
	tree: Restrictions = [],
	restrictions: Restrictions = [],
	getEmptyBuckets?: boolean
): Restrictions => {
	return tree.reduce(
		(buckets, branch) => {
			const isBucketWithId =
				branch?.bucketRestriction &&
				(getEmptyBuckets ||
					(branch.bucketRestriction.bkt &&
						typeof branch.bucketRestriction.bkt.Id === 'number'));

			const isBucketWithSegmentName =
				branch?.segmentMemberRestriction &&
				(getEmptyBuckets || branch.segmentMemberRestriction.segmentName);

			if (isBucketWithId || isBucketWithSegmentName) {
				return [...buckets, branch];
			}

			if (branch?.logicalRestriction) {
				const logicalRestrictions = getAllBuckets(
					branch.logicalRestriction.restrictions,
					buckets
				);

				return [...logicalRestrictions];
			}

			if (branch?.activityRestriction) {
				const activityRestriction = getAllBuckets(
					branch.activityRestriction.restriction.logicalRestriction
						?.restrictions || [],
					buckets
				);

				return [...activityRestriction];
			}

			return [...buckets];
		},
		[...restrictions]
	);
};

const getRuleAllBuckets = (
	tree: Restrictions = [],
	restrictions: Restrictions = []
): Restrictions => {
	return tree.reduce(
		(buckets, branch) => {
			const isBucketWithId =
				branch?.bucketRestriction && branch.bucketRestriction.bkt;

			const isBucketWithSegmentName =
				branch?.segmentMemberRestriction &&
				branch.segmentMemberRestriction.segmentName;

			if (isBucketWithId || isBucketWithSegmentName) {
				return [...buckets, branch];
			}

			if (branch?.logicalRestriction) {
				const logicalRestrictions = getRuleAllBuckets(
					branch.logicalRestriction.restrictions,
					buckets
				);

				return [...logicalRestrictions];
			}

			if (branch?.activityRestriction) {
				const activityRestriction = getRuleAllBuckets(
					branch.activityRestriction.restriction.logicalRestriction
						?.restrictions,
					buckets
				);

				return [...activityRestriction];
			}

			return [...buckets];
		},
		[...restrictions]
	);
};

const setAttributeAttr = (
	type: string,
	attribute: QueryAttribute
): QueryAttribute => {
	const resourceType = type === 'contact' ? 'Contact' : 'LatticeAccount';

	return {
		...attribute,
		resourceType: attribute.resourceType || resourceType,
		attr: `${attribute.resourceType}.${attribute.columnName}`,
	};
};

// #region getRuleCount
const isValidRestriction = (restriction: Restriction): unknown =>
	restriction.bucketRestriction &&
	restriction.bucketRestriction.bkt &&
	restriction.bucketRestriction.bkt.Id &&
	!restriction.bucketRestriction.ignored;

interface RuleCountBkt extends Bkt {
	bucket: string;
}

interface BucketsReduce {
	filteredAccounts: Restrictions;
	filteredContacts: Restrictions;
}

const getRuleCount = (
	bkt: RuleCountBkt,
	bucketToRuleMap: BucketToRuleMap,
	bucketLabels: string[],
	entity?: string
): number | Record<string, number> => {
	const buckets = bkt
		? [bucketToRuleMap[bkt.bucket]]
		: bucketLabels.map((bucketName) => {
				return bucketToRuleMap[bucketName];
		  });

	const {filteredAccounts, filteredContacts} = buckets.reduce(
		({filteredAccounts, filteredContacts}, bucket) => {
			const accountRestrictions: Restrictions = getAllBuckets(
				bucket?.account_restriction?.logicalRestriction?.restrictions
			).filter(isValidRestriction);

			const contactRestrictions: Restrictions = getAllBuckets(
				bucket?.contact_restriction?.logicalRestriction?.restrictions
			).filter(isValidRestriction);

			return {
				filteredAccounts: [...filteredAccounts, ...accountRestrictions],
				filteredContacts: [...filteredContacts, ...contactRestrictions],
			};
		},
		{
			filteredAccounts: [],
			filteredContacts: [],
		} as BucketsReduce
	);

	if (entity) {
		return {
			account: filteredAccounts.length,
			contact: filteredContacts.length,
		};
	}

	return filteredAccounts.length + filteredContacts.length;
};
// #endregion getRuleCount

const refreshCounts = (): Promise<string> => {
	setPublicProperty('disableAllTreeRestrictions', true);

	const segment = isSegmentationV2Enabled()
		? {
				account_restriction:
					getQueryProperty<Restriction>('accountRestriction'),
				contact_restriction:
					getQueryProperty<Restriction>('contactRestriction'),
				member_restriction: getQueryProperty<Restriction>('memberRestriction'),
		  }
		: {
				account_restriction:
					getQueryProperty<Restriction>('accountRestriction'),
				contact_restriction:
					getQueryProperty<Restriction>('contactRestriction'),
		  };

	return updateAllBucketCounts(
		flattenSegmentRestrictions(sanitizeSegment(segment))
	);
};

// #region getListRestrictionsWithTooManyValuesError
interface ErrorNotice {
	title: string;
	message: string;
}

/**
 * List Restrictions are those which allow the user to enter a list of multiple values.
 * Currently, these are restrictions using either the "equal to" or "not equal to" comparison operators.
 *
 * There cannot be more than 5 restrictions with over 200 values because overly large queries can cause the backend to crash.
 * Because we send preview queries to the backend based on current restrictions in the UI, we cannot defer this check to the
 * point in time when we attempt to save the updated restrictions.
 *
 * So, before we merge in a newOrModifiedRestriction, we check if the resulting set of restrictions breaks the above rule.
 * If so, returns an error object which can be used to render an error notice for the user.
 */
const getListRestrictionsWithTooManyValuesError = (
	newOrModifiedRestriction: Restriction
): ErrorNotice | null => {
	const valuesLimit = 200;
	const restrictionsLimit = 5;

	const existingRestrictions = [
		getQueryProperty<Restriction>('accountRestriction'),
		getQueryProperty<Restriction>('contactRestriction'),
	]
		.flatMap(({restriction}) =>
			getAllBuckets(restriction?.logicalRestriction?.restrictions)
		)
		.filter(
			(restrictions) =>
				!restrictions.segmentMemberRestriction ||
				!restrictions.bucketRestriction?.ignored
		);

	const modifiedRestrictionIndex = existingRestrictions.findIndex(
		({labelGlyph, bucketRestriction}) => {
			// labelGlyph is the restriction number rendered in the UI, so it will match if the user just modified an existing restriction
			const isSameIndex = labelGlyph === newOrModifiedRestriction.labelGlyph;
			// attr contains both the entityType (Account|Contact) as well as the internal name of the attribute
			const isSameAttributeAndEntityType =
				bucketRestriction?.attr ===
				newOrModifiedRestriction.bucketRestriction?.attr;

			return isSameIndex && isSameAttributeAndEntityType;
		}
	);

	if (modifiedRestrictionIndex !== -1) {
		existingRestrictions[modifiedRestrictionIndex] = newOrModifiedRestriction;
	} else {
		existingRestrictions.push(newOrModifiedRestriction);
	}

	const enrichmentsState =
		getDataCloudProperty<Attribute[]>('enrichments') || [];
	const eventEnrichmentsState =
		getDataCloudProperty<Attribute[]>('eventEnrichments') || [];

	const restrictionDisplayNames = existingRestrictions.reduce(
		(restrictions, restriction) => {
			if (
				restriction.bucketRestriction?.bkt?.Vals &&
				restriction.bucketRestriction.bkt.Vals.length > valuesLimit
			) {
				const displayName =
					isTimeSeriesSegmentEnabled() &&
					restriction.bucketRestriction.entityType
						? eventEnrichmentsState.find(({AttrName}) =>
								restriction.bucketRestriction?.attr.includes(AttrName)
						  )?.DisplayName
						: enrichmentsState.find(({AttrName}) =>
								restriction.bucketRestriction?.attr.includes(AttrName)
						  )?.DisplayName;

				return [...restrictions, displayName || ''];
			}

			return [...restrictions];
		},
		[] as string[]
	);

	return restrictionDisplayNames.length > restrictionsLimit
		? {
				title: `No more than ${restrictionsLimit} conditions with over ${valuesLimit} values are allowed.`,
				message: `Before proceeding, check these conditions: (${restrictionDisplayNames.join(
					', '
				)})`,
		  }
		: null;
};
// #endregion getListRestrictionsWithTooManyValuesError

const getDataCloudAttributes = (getEmptyBuckets = false): Restrictions => {
	if (isRulesBasedModelMode()) {
		const accountRestrictions = getAllBuckets(
			getQueryProperty<LogicalRestriction>('accountBucketTreeRoot')
				.logicalRestriction?.restrictions || [],
			[],
			getEmptyBuckets
		);

		return getAllBuckets(
			getQueryProperty<LogicalRestriction>('contactBucketTreeRoot')
				.logicalRestriction?.restrictions || [],
			accountRestrictions,
			getEmptyBuckets
		);
	}

	const accountRestrictions = getAllBuckets(
		getQueryProperty<Restriction>('accountRestriction').restriction
			?.logicalRestriction?.restrictions,
		[]
	);

	const contactRestrictions = getAllBuckets(
		getQueryProperty<Restriction>('contactRestriction').restriction
			?.logicalRestriction?.restrictions,
		accountRestrictions
	);

	if (isSegmentationV2Enabled()) {
		return getAllBuckets(
			getQueryProperty<Restriction>('memberRestriction').restriction
				?.logicalRestriction?.restrictions,
			contactRestrictions
		);
	}

	return contactRestrictions;
};

// #region getEntitiesCounts
const getEntitiesCountsByQuery = (
	query?: Query
): Promise<EntitiesCountsResponse> => {
	const queryWithRestriction: Query = !query
		? {
				free_form_text_search: '',
				account_restriction:
					getQueryProperty<Restriction>('accountRestriction'),
				contact_restriction:
					getQueryProperty<Restriction>('contactRestriction'),
				restrict_without_sfdcid: false,
				page_filter: {
					num_rows: 10,
					row_offset: 0,
				},
		  }
		: {
				free_form_text_search: query.free_form_text_search || '',
				account_restriction: query.account_restriction || {},
				contact_restriction: query.contact_restriction || {},
				preexisting_segment_name: query.preexisting_segment_name,
				page_filter: {
					num_rows: query.page_filter?.num_rows || 10,
					row_offset: query.page_filter?.row_offset || 0,
				},
				restrict_without_sfdcid: query.restrict_without_sfdcid,
				use_model_label_query: query.use_model_label_query,
				model_label_query: query.model_label_query,
		  };

	if (isSegmentationV2Enabled()) {
		if (!query) {
			queryWithRestriction.member_restriction =
				getQueryProperty<Restriction>('memberRestriction') || {};
		} else {
			queryWithRestriction.member_restriction = query.member_restriction || {};
		}
	}

	return getEntitiesCountsQuery(sanitizeSegment(queryWithRestriction));
};

const getEntitiesCounts = (
	query?: Query,
	callBack?: () => void
): Promise<EntitiesCountsResponse> =>
	getEntitiesCountsByQuery(query).then((data) => {
		const uiData = {...data};

		setResourceTypeCount('accounts', false, uiData.Account);

		if (isCalculateKnownContactEnabled()) {
			setResourceTypeCount('contacts', false, uiData['Known Contact']);

			setResourceTypeCount('unknowncontacts', false, uiData['UnKnown Contact']);

			// Fixed bug PLS-25774
			uiData.Contact = uiData['Known Contact'] + uiData['UnKnown Contact'];
		} else {
			setResourceTypeCount('contacts', false, uiData.Contact);
		}

		setEntitiesProperty('loading', false);
		if (callBack) callBack();
		return uiData;
	});
// #endregion getEntitiesCounts

// #region removeRestriction
const stringifyBkt = (bkt: Bkt): string => {
	const newBkt = {...bkt};

	delete newBkt.Cnt;

	return JSON.stringify(newBkt);
};

const removeRestriction = (type: string, attribute: QueryAttribute): void => {
	const attributeAttr = setAttributeAttr(type, attribute);

	const restrictionKey = `${type}Restriction` as RestrictionType;

	const restriction =
		getQueryProperty<LogicalRestrictionRecord | undefined>(
			'addBucketTreeRoot'
		) || getQueryProperty<Restriction>(restrictionKey).restriction;

	const {restrictions = []} = restriction?.logicalRestriction || {};

	const existingRestrictions = restrictions.findIndex(
		({bucketRestriction}) =>
			bucketRestriction &&
			bucketRestriction.attr === attributeAttr.attr &&
			stringifyBkt(attributeAttr.bkt) === stringifyBkt(bucketRestriction.bkt!)
	);

	if (existingRestrictions !== -1) {
		restrictions.splice(existingRestrictions, 1);
	}

	setRestrictions({
		type,
		restrictions,
	});
};
// #endregion removeRestriction

// #region addRestriction
const addRestriction = (type: string, attribute: QueryAttribute): void => {
	const attributeAttr = setAttributeAttr(type, attribute);

	const treeRoot = getQueryProperty<LogicalRestrictionRecord | undefined>(
		'addBucketTreeRoot'
	);

	const restrictionKey = `${type}Restriction` as RestrictionType;

	let restrictions = [];

	const bucketRestriction = getBucketRestriction({
		columnName: attributeAttr.columnName,
		objectType: attributeAttr.resourceType as Entity,
		range: attributeAttr.bkt?.Vals as unknown as number,
		attr: attributeAttr.attr!,
		bkt: attributeAttr.bkt,
	});

	if (isRulesBasedModelMode()) {
		bucketRestriction.ignored = true;
	}

	if (isRulesBasedModelMode()) {
		restrictions =
			treeRoot && type === getQueryProperty<string>('addBucketTreeType')
				? treeRoot.logicalRestriction.restrictions
				: getQueryProperty<LogicalRestriction>(
						`${type}BucketTreeRoot` as keyof QueryState
				  ).logicalRestriction?.restrictions || [];
	} else if (
		treeRoot &&
		type === getQueryProperty<string>('addBucketTreeType')
	) {
		restrictions = treeRoot.logicalRestriction.restrictions;
	} else {
		restrictions =
			getQueryProperty<Restriction>(restrictionKey).restriction
				?.logicalRestriction?.restrictions || [];
	}

	restrictions.push({
		bucketRestriction,
	});

	setRestrictions({
		type,
		restrictions,
	});
};
// #endregion addRestriction

const toggleRestrictionEntity = (
	toggle: 'remove' | 'add',
	restrictionEntity: 'Contact' | 'Account',
	attribute: QueryAttribute,
	callBack: () => void
): void => {
	if (toggle === 'remove') {
		removeRestriction(restrictionEntity.toLowerCase(), attribute);

		getEntitiesCounts(undefined, callBack);

		return;
	}

	if (toggle === 'add') {
		addRestriction(restrictionEntity.toLowerCase(), attribute);

		getEntitiesCounts(undefined, callBack);

		return;
	}
};

// #region resetRestrictions
const getEntity = (entity: string): 'account' | 'contact' | 'member' => {
	switch (entity) {
		case 'account':
		case 'purchasehistory':
			return 'account';
		case 'contact':
			return 'contact';
		case 'member':
			return 'member';
		default:
			return 'account';
	}
};

const resetRestrictions = (
	segment: ISegment | null,
	callBack?: () => void
): void => {
	setEntitiesProperty('loading', true);
	const showSegmentationV2 = isSegmentationV2Enabled();

	entities.forEach((entity) => {
		const newEntityRestriction = {
			...getQueryProperty<Restriction>(`${getEntity(entity)}Restriction`),
		};

		const restriction = newEntityRestriction.restriction?.logicalRestriction;

		if (restriction) {
			restriction.operator = Operator.AND;

			const restrictionKey:
				| 'account_restriction'
				| 'contact_restriction'
				| 'member_restriction' = `${getEntity(entity)}_restriction`;

			if (segment && segment[restrictionKey]) {
				restriction.restrictions =
					segment[restrictionKey].restriction.logicalRestriction
						?.restrictions || [];
				restriction.operator =
					segment[restrictionKey].restriction.logicalRestriction?.operator ||
					Operator.AND;

				if (showSegmentationV2 && entity === 'member') {
					restriction.compositeSegmentBlock =
						segment[restrictionKey].restriction.logicalRestriction
							?.compositeSegmentBlock || false;
				}
			} else if (showSegmentationV2 && entity === 'member') {
				if (restriction.restrictions[0]?.logicalRestriction) {
					restriction.restrictions[0].logicalRestriction.restrictions = [];
				}

				if (restriction.restrictions[1]?.logicalRestriction) {
					restriction.restrictions[1].logicalRestriction.restrictions = [];
				}
			} else {
				restriction.restrictions = [];
			}

			setQueryPropertyValue(
				`${getEntity(entity)}Restriction`,
				newEntityRestriction
			);
		}
	});

	getEntitiesCounts(undefined, callBack);
};
// #endregion resetRestrictions

const setupStore = (segment: ISegment | null): void => {
	dispatchQueryPropertyValue<ISegment | null>('segment', segment);

	resetRestrictions(segment);

	const accountRestriction = JSON.stringify(
		segment?.account_restriction ??
			getQueryProperty<Restriction>('accountRestriction')
	);

	const contactRestriction = JSON.stringify(
		segment?.contact_restriction ??
			getQueryProperty<Restriction>('contactRestriction')
	);

	if (isSegmentationV2Enabled()) {
		const memberRestriction = JSON.stringify(
			segment?.member_restriction ??
				getQueryProperty<Restriction>('memberRestriction')
		);

		dispatchQueryPropertyValue<string>(
			'defaultRestrictions',
			accountRestriction + contactRestriction + memberRestriction
		);
	} else {
		dispatchQueryPropertyValue<string>(
			'defaultRestrictions',
			accountRestriction + contactRestriction
		);
	}
};

/**
 * Combine root restrictions per operator.
 * @param op Refers to @Operator
 * @param restrictions Restrictions @Restriction
 */
const combineRestrictions = (
	op: Operator,
	restrictions: Restriction[]
): Restriction => {
	return {
		restriction: {
			logicalRestriction: {
				operator: op,
				restrictions: restrictions.map(({restriction}) => restriction!),
			},
		},
	};
};

/**
 * Create segment restriction by operator.
 * @param name Segment name
 * @param op @Operator
 * @returns The created segment restriction by operator.
 */
const createSegmentRestriction = (name: string, op: Operator): Restriction => {
	const restrictions = [
		{
			segmentMemberRestriction: {
				entity: AttributeEntity.AccountSegmentMember,
				segmentName: name,
				ignored: false,
				relation: 'INCLUDE',
			},
		},
	];
	return {
		restriction: {
			logicalRestriction: {
				compositeSegmentBlock: true,
				operator: Operator.AND,
				restrictions: [
					{
						logicalRestriction: {
							operator: Operator.OR,
							restrictions: op === Operator.OR ? restrictions : [],
						},
					},
					{
						logicalRestriction: {
							operator: Operator.AND,
							restrictions: op === Operator.AND ? restrictions : [],
						},
					},
				],
			},
		},
	};
};

interface IResolveQueryRestriction {
	subSegment: string | null;
	newAccountRestriction: LogicalRestriction | null;
	isEdit: boolean;
	callBack: (result: {
		accountRestrictions: Restriction;
		contactRestrictions: Restriction;
		memberRestrictions?: Restriction;
	}) => void;
	ssviCardName?: string;
}

const resolveQueryRestriction = async function ({
	ssviCardName,
	subSegment,
	newAccountRestriction,
	isEdit,
	callBack,
}: IResolveQueryRestriction): Promise<void> {
	const accountRestriction =
		getQueryProperty<Restriction>('accountRestriction');
	const contactRestriction =
		getQueryProperty<Restriction>('contactRestriction');
	const memberRestriction = isSegmentationV2Enabled()
		? getQueryProperty<Restriction>('memberRestriction')
		: null;
	const nullRestriction: LogicalRestriction = {
		operator: Operator.AND,
		restrictions: [],
	};
	if (!isEdit) {
		let memberRestrictionForCreateSubSegment = null;
		let newSegment = null;
		if (subSegment) {
			newSegment = {
				account_restriction: {
					restriction: {
						logicalRestriction: angular.copy(nullRestriction),
					},
				},
				contact_restriction: {
					restriction: {
						logicalRestriction: angular.copy(nullRestriction),
					},
				},
			} as ISegment;
			let subSegmentAccounts = -1;
			await getSegmentByName(subSegment)
				.then((result) => {
					subSegmentAccounts = result.accounts!;
				})
				.then(function () {
					memberRestrictionForCreateSubSegment = {
						compositeSegmentBlock: true,
						operator: Operator.AND,
						restrictions: [
							{
								logicalRestriction: {
									operator: Operator.OR,
									restrictions: [],
								},
							},
							{
								logicalRestriction: {
									operator: Operator.AND,
									restrictions: [
										{
											segmentMemberRestriction: {
												entity: AttributeEntity.AccountSegmentMember,
												segmentName: subSegment,
												ignored: false,
												bkt: {
													Cnt: subSegmentAccounts,
												},
												relation: 'INCLUDE',
											},
										},
									],
								},
							},
						],
					};
				})
				.catch(() => {
					return;
				});
		}
		accountRestriction.restriction = {
			logicalRestriction:
				newAccountRestriction || angular.copy(nullRestriction),
		};
		contactRestriction.restriction = {
			logicalRestriction: angular.copy(nullRestriction),
		};
		if (newAccountRestriction) {
			newSegment = {
				ssviCardName,
				account_restriction: {
					restriction: accountRestriction.restriction,
				},
			} as ISegment;
		}
		if (
			newSegment &&
			memberRestriction &&
			memberRestrictionForCreateSubSegment
		) {
			memberRestriction.restriction = {
				logicalRestriction: memberRestrictionForCreateSubSegment,
			};
			memberRestriction.restriction.logicalRestriction =
				memberRestrictionForCreateSubSegment;
			newSegment.member_restriction = {
				restriction: memberRestriction.restriction,
			};
		}
		if (newSegment) {
			newSegment.name = SegmentCreate;
			setPublicProperty('enableSaveSegmentButton', true);
		}
		setupStore(newSegment);
	}
	callBack({
		accountRestrictions: accountRestriction,
		contactRestrictions: contactRestriction,
		...(memberRestriction ? {memberRestrictions: memberRestriction} : {}),
	});
};

const getCurrentSegmentName = (): string => {
	try {
		const sessionSegmentState = getSessionSegmentState();

		const keyPrefix = UserValues.getLongFormTenantId();

		const queryKey = `${keyPrefix}.${
			sessionSegmentState?.isCompanyList
				? 'myDataQueryResultsAccountsView'
				: 'myDataQueryResultsContactsView'
		}`;

		const selectedSegment = JSON.parse(
			window.sessionStorage.getItem(queryKey) ?? '{}'
		);

		const segmentName = selectedSegment?.segment ?? '';

		return segmentName.includes('Segment_') ? segmentName : '';
	} catch {
		const [, , , , , segmentName = ''] = window.location.pathname.split('/');

		return segmentName;
	}
};

export {
	isValidResourceType,
	getAllActivityBuckets,
	updateAllBucketCounts,
	setAccounts,
	setContacts,
	generateBucketLabel,
	getAllBuckets,
	getRuleAllBuckets,
	setAttributeAttr,
	getRuleCount,
	refreshCounts,
	getListRestrictionsWithTooManyValuesError,
	getDataCloudAttributes,
	getEntitiesCounts,
	toggleRestrictionEntity,
	resetRestrictions,
	setupStore,
	combineRestrictions,
	createSegmentRestriction,
	resolveQueryRestriction,
	getCurrentSegmentName,
};
