import {axios, AxiosRequestHeaders} from 'common/network.vendor';
import {
	ApiMethod,
	ApiResponseType,
	ApiType,
	IAPIConfig,
	IApiDataType,
	IApiErrorResponse,
	IApiResponse,
	IGenericApiResponse,
} from '../Data/API/ApiType';
import {delay} from './CommonUtil';
import {
	doesObjectTypeContainEmptyField,
	ObjectArray,
	ObjectType,
} from '../Data/ConnectionType';
import {
	getUIAction,
	IUIActionV2,
	IUIActionV3,
	UIActionView,
} from '../Data/API/UIAction';
import {BannerLogger, LoggerType} from './Logger';
import {APITypeConfig, DefaultResponse} from '../Data/API/ApiConst';

const IsMockMode = false;
const MockConfig = {
	Delay: 0,
};

type IFetchData = ObjectType | ObjectArray | string[];

interface IFetch extends ObjectType {
	apiType?: ApiType;
	apiConfig?: IAPIConfig;
	param?: ObjectType;
	query?: ObjectType;
	headers?: AxiosRequestHeaders;
	data?: IFetchData;
	mock?: boolean | IFetchData;
}

const isFetchPropFieldValid = (obj?: ObjectType): boolean => {
	return !obj || !doesObjectTypeContainEmptyField(obj);
};

const isFetchPropValid = ({param, query, data, mock}: IFetch): boolean => {
	return (
		!!mock ||
		(Array.isArray(data)
			? isFetchPropFieldValid(param) && isFetchPropFieldValid(query)
			: isFetchPropFieldValid(param) &&
			  isFetchPropFieldValid(query) &&
			  isFetchPropFieldValid(data))
	);
};

const UnknownError = 'Unknown Error';

async function genericFetch<T extends IApiDataType>({
	apiType,
	apiConfig,
	param,
	query,
	headers,
	data,
	mock,
}: IFetch): Promise<IGenericApiResponse<T>> {
	const isValid = apiType || apiConfig;
	const useAPIMock = IsMockMode || mock === true;
	if (isValid && (!mock || useAPIMock)) {
		const {
			url,
			method = ApiMethod.get,
			responseType = ApiResponseType.Object,
		} = (apiType ? APITypeConfig[apiType] : apiConfig) as IAPIConfig;
		try {
			const response = (await axios({
				url: url(param || {}),
				method,
				params: useAPIMock ? {...query, mock: true} : query,
				headers,
				data,
			})) as IGenericApiResponse<T>;
			const uiAction = getUIAction(response);
			if (uiAction) {
				const {view, message, title} = uiAction;
				if (view === UIActionView.Banner) {
					BannerLogger.log({
						message: message || title,
						loggerType: LoggerType.Log,
					});
				}
				return {
					...(DefaultResponse[responseType] as IGenericApiResponse<T>),
					status: 200,
				};
			}
			const {Errors = [], Success = true} = response.data as IUIActionV2;
			if (!Success) {
				BannerLogger.log({
					message: Errors?.[0] || UnknownError,
					loggerType: LoggerType.Warn,
				});
				return DefaultResponse[responseType] as IGenericApiResponse<T>;
			}
			const {errorMsg} = response.data as IUIActionV3;
			if (errorMsg) {
				BannerLogger.log({
					message: errorMsg,
					loggerType: LoggerType.Warn,
				});
				return DefaultResponse[responseType] as IGenericApiResponse<T>;
			}
			return response;
		} catch (error) {
			const response = (error as ObjectType)
				?.response as IGenericApiResponse<T>;
			const uiAction = getUIAction(response);
			if (uiAction) {
				const {view, message} = uiAction;
				if (view === UIActionView.Banner) {
					BannerLogger.log({
						message,
						loggerType: LoggerType.Error,
					});
				}
			} else {
				const {errorMsg} = response.data as IUIActionV3;
				if (errorMsg) {
					BannerLogger.log({
						message: errorMsg,
						loggerType: LoggerType.Error,
					});
				} else {
					const message =
						(response as IApiErrorResponse)?.statusText || UnknownError;
					BannerLogger.log({
						message,
						loggerType: LoggerType.Error,
					});
				}
			}
			return DefaultResponse[responseType] as IGenericApiResponse<T>;
		}
	}

	const {Delay} = MockConfig;
	await delay(Delay);
	return {status: 200, data: mock} as IGenericApiResponse<T>;
}

async function fetch(props: IFetch): Promise<IApiResponse> {
	return genericFetch(props);
}

export type {IFetch};
export {fetch, genericFetch, IsMockMode, isFetchPropValid};
