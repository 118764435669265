import {BrokerOwnerType} from 'atlas/connectors/EIF/Data/API/BE/BrokerAuth';
import {ObjectType} from '../../ConnectionType';
import {SystemType} from '../../SystemType';

enum BrokerInitiatorType {
	Lattice = 'Lattice',
}

interface IActivationAuthList extends ObjectType {
	created: number;
	updated: number;
	authId: string;
	lookupMapConfigId: string | null;
	pid: number;
	solutionInstanceId: string;
	trayAuthenticationId: string;
	trayWorkflowEnabled: boolean;
	expirationDate: number;
}

interface IActivationAuthResponse extends ObjectType {
	created: number;
	updated: number;
	name: string;
	externalId: string;
	systemType: SystemType;
	brokerAuthInfo: {
		baseUrl: string;
		encryptedPassword: string;
		siteName: string;
		userName: string;
		expirationDate: number;
		expirationDateFormat: string;
		isSandbox?: boolean;
	} | null;
	owner: BrokerOwnerType;
	initiator: BrokerInitiatorType;
}

const DefaultBrokerAuthInfo = {
	created: 0,
	updated: 0,
	name: '',
	externalId: '',
	brokerAuthInfo: {
		baseUrl: '',
		siteName: '',
		userName: '',
		expirationDate: 0,
	},
};

const BrokerAuthActivationUrl = '/pls/brokerAuth/createWithTray';

export {BrokerAuthActivationUrl, DefaultBrokerAuthInfo};

export type {IActivationAuthList, IActivationAuthResponse};
