import RBAC from 'common/app/utilities/RoleBasedAccessControl/RBAC';
import {
	RBACActions,
	RBACInterface,
} from 'common/app/utilities/RoleBasedAccessControl/RBAC.enums';
import {
	isCalculateKnownContactEnabled,
	isTimeSeriesSegmentEnabled,
	isSegmentationV2Enabled,
	isEnableQueryBuilderRedesign,
} from 'common/stores/tenantConfig';
import {
	getEnrichments,
	getEnrichmentsMap,
	getDataCloudProperty,
	getEventEnrichments,
} from 'common/stores/datacloud';
import {
	isSegmentationMode,
	isRulesBasedModelMode,
	getPublicProperty,
	getQueryProperty,
	dispatchSetAddBucketTreeRoot,
} from 'common/stores/query';
import {getMetadataForCategory} from '../../../datacloud.service.vanilla';
import template from './tree.component.html';
import {getBktValues, isBucketUsed, updateBucketCount} from './tree.helpers';
import {defaultRangeConfig} from 'common/components/datacloud/query/advanced/tree/edit/TreeActivityEdit/treeActivityEdit.constants';
import {
	twoInputs,
	numericalOperations,
	enumOperations,
	noInputs,
} from './tree.constants';
import NoticeService from '../../../../notice/NoticeService';
import {getListRestrictionsWithTooManyValuesError} from '../../query.helpers';
import {Entity} from '../../query.enums';

const fundamentalTypeToBucketType = {
	alpha: 'String',
	boolean: 'Boolean',
	numeric: 'Numerical',
	date: 'Date',
};

angular
	.module('common.datacloud.query.builder.tree', [
		'common.datacloud.query.builder.tree.info',
		'common.datacloud.query.builder.tree.edit',
		'common.datacloud.query.builder.tree.activity',
		'common.datacloud.query.builder.tree.edit.activity',
		'common.datacloud.query.builder.tree.edit.transaction',
		'common.datacloud.query.builder.tree.edit.transaction.edit',
		'common.datacloud.query.builder.tree.edit.percent.edit',
		'common.datacloud.query.builder.tree.edit.percent.item',
		'common.datacloud.query.builder.tree.edit.date.attribute',
		'common.datacloud.query.builder.tree.edit.enum.attribute',
	])
	.directive('queryTreeDirective', function () {
		return {
			restrict: 'AE',
			scope: {
				root: '=',
				tree: '=',
				parent: '=',
				entity: '=',
				segment: '=',
				memberType: '=',
			},
			template,
			controllerAs: 'vm',
			controller($scope, $timeout, $filter, AddAttributesModal) {
				const vm = this;

				angular.extend(vm, {
					root: $scope.root.baseController || $scope.root,
					tree: $scope.tree,
					parent: $scope.parent,
					items: $scope.items,
					segment: $scope.segment,
					memberType: $scope.memberType,
					isListSegment: getQueryProperty('segment')?.type === 'List',
					entity: $scope.entity,
					entityLowerCase: $scope.entity.toLowerCase(),
					enrichments: [],
					eventEnrichments: [],
					enrichmentsMap: getEnrichmentsMap(),
					eventEnrichmentsMap: getDataCloudProperty('eventEnrichmentsMap'),
					type: '',
					label: '',
					range: [],
					operation: '',
					uniqueId: Math.random() * (8 << 8),
					editMode: 'Custom',
					records_updating: false,
					enable_calculate_known_contact: isCalculateKnownContactEnabled(),
					showSegmentationV2: isSegmentationV2Enabled(),
					enableQueryBuilderRedesign: isEnableQueryBuilderRedesign(),
					showTimeSeries: isTimeSeriesSegmentEnabled(),
					activityRestriction: null,
					subType: 'Known',
					numerical_operations: numericalOperations,
					enum_operations: enumOperations,
					no_inputs: noInputs,
					two_inputs: twoInputs,
					canEdit: $scope.segment
						? !$scope.segment.viewOnly &&
						  RBAC.hasAccess(RBACInterface.SEGMENTS, RBACActions.EDIT) &&
						  ($scope.root.inRatingEngine
								? RBAC.hasAccess(RBACInterface.MODELS, RBACActions.EDIT)
								: true)
						: true,
				});

				vm.init = function (type, value) {
					vm.tree.collapsed = false;
					if (
						vm.enable_calculate_known_contact &&
						vm.entityLowerCase === 'contact' &&
						vm.tree.logicalRestriction &&
						vm.tree.logicalRestriction.restrictions
					) {
						vm.subType = vm.root.initKnownContactAttribute(vm.tree);
					}

					getEnrichments().then(function (enrichments) {
						vm.enrichments = enrichments;
						if (vm.tree.bucketRestriction) {
							if (vm.tree.bucketRestriction.ignored === undefined) {
								vm.tree.bucketRestriction.ignored = false;
							}

							if (vm.showTimeSeries && vm.tree.bucketRestriction.entityType) {
								return;
							}

							const [Entity, ColumnId] =
								vm.tree.bucketRestriction.attr.split('.');

							if (vm.showSegmentationV2) {
								if (
									vm.root.entityList &&
									!vm.root.entityList[vm.entity].includes(Entity)
								) {
									return;
								}
							}

							const [item] = $filter('filter')(
								vm.enrichments,
								{Entity, ColumnId},
								true
							);
							vm.item = item;

							if (vm.item) {
								vm.root.pushItem(vm.item, vm.tree.bucketRestriction, vm);
								if (vm.item.cube && vm.item.cube.Bkts) {
									vm.type = vm.item.cube.Bkts.Type;
								} else {
									//FIXME: if there is no Bkts, it is most likely a non-bucketable text field (YSong, Jan-2018)
									vm.type = 'String';
								}
							}

							vm.label = vm.tree.bucketRestriction.bkt
								? vm.tree.bucketRestriction.bkt.Lbl
								: '';
							vm.range = getBktValues(vm.tree.bucketRestriction, vm.type);
						}
						if (vm.showSegmentationV2) {
							if (vm.tree.segmentMemberRestriction) {
								const {entity, segmentName} = vm.tree.segmentMemberRestriction;
								const [segment] = $filter('filter')(
									vm.root.segmentsList,
									{name: segmentName},
									true
								);

								vm.item = {
									Entity: entity,
									ColumnId: segmentName,
									DisplayName: segment.display_name,
									name: segment.name,
									Category: `${segment.dataType} ${
										segment.type === 'List' ? '' : 'Segments'
									}`,
								};

								vm.tree.segmentMemberRestriction.bkt = {Cnt: segment.accounts};

								if (vm.item) {
									vm.root.pushItem(
										vm.item,
										vm.tree.segmentMemberRestriction,
										vm
									);
									vm.type = 'Member';
								}
								vm.label = '';
							}
						}
					});
					if (vm.showTimeSeries) {
						vm.rangeConfig = defaultRangeConfig;
						getEventEnrichments().then(function (eventEnrichments) {
							vm.eventEnrichments = eventEnrichments;

							if (vm.parent) {
								const activityRestriction =
									vm.parent.logicalRestriction.restrictions.filter(
										(item) =>
											item.activityRestriction &&
											item.activityRestriction.restriction === vm.tree
									);
								vm.activityRestriction =
									activityRestriction.length > 0
										? activityRestriction[0]
										: null;
							}
							if (vm.tree.bucketRestriction) {
								if (vm.tree.bucketRestriction.ignored === undefined) {
									vm.tree.bucketRestriction.ignored = false;
								}
								if (!vm.tree.bucketRestriction.entityType) {
									return;
								}
								const {entityType: Entity, attr} = vm.tree.bucketRestriction;
								if (!Entity) {
									return;
								}
								const [entity, ColumnId] = attr.split('.');

								if (
									vm.root.entityList &&
									!vm.root.entityList[`${entity}ActivityStream`].includes(
										Entity
									)
								) {
									return;
								}

								const [item] = $filter('filter')(
									vm.eventEnrichments,
									{Entity, ColumnId},
									true
								);
								vm.item = item;

								if (vm.item) {
									vm.root.pushItem(vm.item, vm.tree.bucketRestriction, vm);
									vm.type =
										fundamentalTypeToBucketType[item.FundamentalType] ||
										'String';
								}

								vm.label = '';
							}
						});
					}
				};

				/**
				 * Flag indicates if segment section is disabled.
				 * When entity is member and segment name is the same as customParentSegment.
				 */
				vm.isSegmentSectionDisabled = () => {
					return (
						$scope.entity === Entity.Member &&
						$scope.segment.customParentSegmentName === vm.item?.name
					);
				};

				vm.doesBucketExist = () => {
					const bucket = vm.tree.bucketRestriction;
					if (!bucket) {
						return false;
					}
					const {attr, entityType} = bucket;
					const isActivity = !!entityType;
					const key = isActivity ? `${entityType}.${attr.split('.')?.[1]}` : attr;
					const map = isActivity ? getDataCloudProperty('eventEnrichmentsMap') : getDataCloudProperty('enrichmentsMap');
					return key in map;
				}

				vm.isBucketUsed = function (bucket) {
					return isBucketUsed(bucket);
				};

				vm.checkSelected = function (bucket) {
					// Remove this method and remove vm.range/vm.label since they should not be necessary
					if (
						bucket.Vals &&
						bucket.Vals[0] == vm.range[0] &&
						bucket.Vals[1] == vm.range[1]
					) {
						vm.presetOperation = bucket.Lbl;
					}
				};

				vm.changePreset = function (bucket) {
					if (!vm.canEdit) {
						return false;
					}
					const label = vm.presetOperation;
					const buckets = vm.item.cube.Bkts.List;

					if (bucket == undefined)
						bucket = buckets.filter(function (item) {
							return item.Lbl == label;
						})[0];

					const restriction = vm.tree.bucketRestriction.bkt;
					const bkt = angular.copy(bucket);

					restriction.Cmp = bkt.Cmp;
					restriction.Id = bkt.Id;
					restriction.Cnt = bkt.Cnt;
					restriction.Lbl = bkt.Lbl;

					if (bkt.Vals[0] !== undefined) {
						if (restriction.Vals[0]) {
							restriction.Vals[0] = bkt.Vals[0];
						} else {
							restriction.Vals = [bkt.Vals[0]];
						}
					} else if (restriction.Vals[0]) {
						restriction.Vals.length = 0;
					}

					if (bkt.Vals[1] !== undefined) {
						if (restriction.Vals[1]) {
							restriction.Vals[1] = bkt.Vals[1];
						} else {
							restriction.Vals.push(bkt.Vals[1]);
						}
					} else if (restriction.Vals[1]) {
						restriction.Vals.splice(1, 1);
					}
				};

				vm.changeRelationValue = function (relation) {
					if (!vm.canEdit) {
						return false;
					}

					const restriction = vm.tree.segmentMemberRestriction;
					restriction.relation = relation;
				};

				vm.changeEntityValue = function (entity) {
					if (!vm.canEdit) {
						return false;
					}

					const restriction = vm.tree.segmentMemberRestriction;
					restriction.entity = entity;
				};

				vm.editBucket = () => {
					if (!vm.canEdit) {
						return;
					}
					if (
						vm.root.draggedItem === vm ||
						getPublicProperty('disableAllTreeRestrictions')
					) {
						return;
					}
					if (
						!vm.editing &&
						!vm.root.draggedItem &&
						(vm.type === 'Date' ||
							vm.type === 'Boolean' ||
							vm.type === 'Numerical' ||
							vm.type === 'Enum' ||
							vm.type === 'TimeSeries' ||
							vm.type === 'String' ||
							vm.type === 'PercentChange' ||
							vm.type === 'Member')
					) {
						// In a rules based model, starting to edit a bucket needs to set
						// `bkt` because the initial restrictions sent by the server and
						// the bkt set when pressing the unset button may cause errors to
						// be thrown in the indivudual bucket services
						// (QueryTreeAccountEntityService and QueryTreePurchaseHistoryService)
						if (isRulesBasedModelMode()) {
							if (vm.tree.bucketRestriction.ignored) {
								if (vm.type !== 'String' && vm.item?.cube?.Bkts?.List) {
									vm.item.topbkt = angular.copy(vm.item.cube.Bkts.List[0]);
									vm.tree.bucketRestriction.bkt = angular.copy(
										vm.item.cube.Bkts.List[0]
									);
								} else if (vm.item?.topbkt) {
									vm.tree.bucketRestriction.bkt = {
										...angular.copy(vm.item.topbkt),
										Id: angular.copy(vm.item.topbkt.Id || -1),
									};
								} else {
									const Cnt = vm.item?.cube?.Cnt || -1;
									vm.item.topbkt = {
										Lbl: ' ',
										Cmp: 'IS_NOT_NULL',
										Id: -1,
										Cnt,
										Vals: [''],
									};

									vm.tree.bucketRestriction.bkt = {
										Lbl: ' ',
										Cmp: 'IS_NOT_NULL',
										Id: -1,
										Cnt,
										Vals: [''],
									};
								}

								vm.label = vm.tree.bucketRestriction.bkt.Lbl;
								vm.range = vm.tree.bucketRestriction.bkt.Vals;
							}
						}

						vm.root.saveState(true);
						vm.editing = true;
					}
				};

				vm.editActivityNumber = () => {
					if (!vm.canEdit) {
						return;
					}
					if (
						vm.root.draggedItem === vm ||
						getPublicProperty('disableAllTreeRestrictions')
					) {
						return;
					}
					vm.editing = true;
				};

				vm.updateBucketCount = async function (segmentName) {
					if (isSegmentationMode()) {
						vm.records_updating = true;

						if (vm.tree.bucketRestriction) {
							const data = await updateBucketCount(
								angular.copy(vm.tree.bucketRestriction),
								segmentName
							);

							if (typeof data == 'number') {
								vm.tree.bucketRestriction.bkt.Cnt = data;
							}
							vm.records_updating = false;
						}
					}
				};

				vm.addAttribute = function (type, relation) {
					if (!vm.canEdit) {
						return false;
					}

					dispatchSetAddBucketTreeRoot(vm.tree, vm.entity.toLowerCase());

					if (vm.showSegmentationV2) {
						const opt = {
							type,
							entity: vm.entity,
							activityRestriction: vm.activityRestriction,
							numerical_operations: vm.numerical_operations,
							usableAttributes:
								type === 'Event' ? vm.eventEnrichments : vm.enrichments,
							root: vm.root,
							relation,
						};
						AddAttributesModal.show(opt);
					} else {
						this.root.saveState();
						this.root.goAttributes();
					}
				};

				vm.mouseDown = function (event) {
					if (!vm.canEdit || vm.editing) {
						return false;
					}

					vm.root.draggedItem = null;

					vm.root.mouseDownTimer = $timeout(function () {
						vm.root.draggedItem = vm;
						vm.root.mouseDownTimer = false;
						vm.mouseMove(event);
					}, 350);
				};

				vm.mouseMove = function (event, dashedItem, append) {
					if (!vm.canEdit || vm.editing) {
						return false;
					}
					const dragged = vm.root.draggedItem;

					if (dragged) {
						const rect = event.currentTarget.getBoundingClientRect(),
							offsetY = event.clientY - rect.top;

						if (!dashedItem) {
							vm.root.droppedItemAppend = offsetY / rect.height >= 0.5;
						} else if (append) {
							vm.root.droppedItemAppend = append || false;
						}

						if (!vm.root.draggedClone || !vm.root.draggedContainer) {
							vm.root.draggedContainer = angular.element(
								'.advanced-query-builder'
							);

							vm.root.draggedClone = angular.element(
								event.currentTarget.parentNode.cloneNode(false)
							);
							vm.root.draggedClone.append(event.currentTarget.cloneNode(true));

							vm.root.draggedContainer.append(vm.root.draggedClone);
							vm.root.draggedClone
								.addClass('query-section')
								.addClass('dragging');
						}

						vm.rect = vm.root.draggedContainer[0].getBoundingClientRect();

						const x = event.clientX - vm.rect.left + 10;
						const y = event.clientY - vm.rect.top - 51;
						const t = 'translate(' + x + 'px,' + y + 'px) scale(0.8, 0.8)';

						vm.root.draggedClone.css({
							'-webkit-transform': t,
							'-moz-transform': t,
							'-ms-transform': t,
							'transform': t,
						});
					}
				};

				vm.mouseOver = function (event) {
					if (!vm.canEdit || vm.editing) {
						return false;
					}

					const dragged = vm.root.draggedItem,
						dropped = vm.root.droppedItem;

					if (
						dragged &&
						(!dropped ||
							(dropped && dropped.tree.$$hashKey !== vm.tree.$$hashKey))
					) {
						if (vm.showTimeSeries) {
							/** isolate activity and accont/contact
							 *  1 activity include under css 'query-section-item-box-activity'
							 *  2 root css 'query-tree-root'
							 *  3 activity has 'entityType' attr
							 * */
							function isActivityAttribute(node) {
								while (!node.className.includes('query-tree-root')) {
									node = node.parentElement;
									if (
										node.className.includes('query-section-item-box-activity')
									) {
										return true;
									}
								}
								return false;
							}

							const {entityType} =
								dragged.tree.bucketRestriction ||
								dragged.tree.segmentMemberRestriction;

							const isActivity = isActivityAttribute(event.target);

							if ((!entityType && isActivity) || (entityType && !isActivity)) {
								return false;
							}
						}
						vm.root.droppedItem = vm;
					}
				};

				vm.addOperator = function (tree) {
					if (!vm.canEdit) {
						return false;
					}
					const operator =
						tree.logicalRestriction.operator == 'AND' ? 'OR' : 'AND';

					this.root.saveState();

					if (tree.logicalRestriction) {
						tree.logicalRestriction.restrictions.push({
							logicalRestriction: {
								operator: operator,
								restrictions: [],
							},
						});
					}
				};

				vm.addEventBucket = function (tree) {
					if (!vm.canEdit) {
						return false;
					}

					this.root.saveState();

					if (tree.logicalRestriction) {
						tree.logicalRestriction.restrictions.push({
							activityRestriction: {
								cnt: [1],
								cmp: 'GREATER_OR_EQUAL',
								count: -1,
								restriction: {
									logicalRestriction: {
										operator: 'AND',
										restrictions: [],
									},
								},
							},
						});
					}
				};

				vm.clickOperator = function () {
					if (!vm.canEdit) {
						return false;
					}
					$timeout(function () {
						vm.root.saveState();
					}, 50);
				};

				vm.clickSubType = function () {
					if (!vm.canEdit) {
						return false;
					}
					if (vm.root.contact_restriction) {
						const restrictions =
							vm.root.contact_restriction.restriction.logicalRestriction
								.restrictions;

						vm.root.updateRestriction(restrictions, vm.subType);

						$timeout(function () {
							vm.root.saveState();
						}, 50);
					}
				};

				vm.clickCollapsed = function () {
					// FIXME - collapsed property is weeded out of equivalency check
					//vm.root.saveState(true); // true wont update counts

					vm.tree.collapsed = !vm.tree.collapsed;
				};

				vm.clickClone = () => {
					if (!vm.canEdit) {
						return;
					}

					const {restrictions} = vm.parent.logicalRestriction;

					const restrictionToCloneIndex = restrictions.findIndex(
						(restriction) => restriction === vm.tree
					);

					if (restrictionToCloneIndex > -1) {
						const clonedRestriction = angular.copy(
							restrictions[restrictionToCloneIndex]
						);
						if (!vm.root.isRules) {
							// model need update totalRules
							vm.root.setTotalRuleCount();
						}
						clonedRestriction.labelGlyph = vm.root.totalRules
							? vm.root.totalRules + 1
							: '-';

						const error =
							getListRestrictionsWithTooManyValuesError(clonedRestriction);

						if (error) {
							NoticeService.warning(error);
							return;
						}

						/**
						 * Splice clonedRestriction in at adjacent index of the original restriction,
						 * so that the clonedRestriction will be right after the original restriction in the UI.
						 */
						restrictions.splice(restrictionToCloneIndex, 0, clonedRestriction);
						vm.root.updateCount();
						vm.root.setTotalRuleCount();
					}
				};

				vm.inSegmentation = isSegmentationMode;
				vm.inRulesBasedModel = isRulesBasedModelMode;
				vm.showToggleIgnored = () => vm.canEdit && isSegmentationMode();

				vm.setIgnored = (isIgnored) => {
					if (!vm.canEdit) return;

					// If a user tries to enable a restriction, we need to run validation
					if (!isIgnored && vm.tree.bucketRestriction) {
						const validationError = getListRestrictionsWithTooManyValuesError(
							vm.tree
						);
						if (validationError) {
							NoticeService.warning(validationError);
							return; // Early return for no-op if validation fails
						}
					}
					if (vm.tree.bucketRestriction) {
						vm.tree.bucketRestriction.ignored = isIgnored;
						const {entityType} = vm.tree.bucketRestriction;
						if (!entityType) {
							vm.updateBucketCount();
						}
					}
					if (vm.tree.segmentMemberRestriction && vm.showSegmentationV2) {
						vm.tree.segmentMemberRestriction.ignored = isIgnored;
					}

					vm.root.updateCount();
				};

				vm.setEnabled = (isEnabled) => vm.setIgnored(!isEnabled);

				vm.clickDelete = function () {
					if (!vm.canEdit) {
						return false;
					}

					vm.parent.logicalRestriction.restrictions.forEach(function (
						item,
						index
					) {
						if (item == vm.tree) {
							if (vm.parent.bucketRestriction || vm.parent.logicalRestriction) {
								vm.parent.logicalRestriction.restrictions.splice(index, 1);
							}
						}
						if (vm.showTimeSeries) {
							if (
								item.activityRestriction &&
								item.activityRestriction.restriction === vm.tree
							) {
								if (
									vm.parent.bucketRestriction ||
									vm.parent.logicalRestriction
								) {
									vm.parent.logicalRestriction.restrictions.splice(index, 1);
								}
							}
						}
					});
					vm.root.saveState();
					// vm.root.updateCount();
					vm.root.setTotalRuleCount();
				};

				vm.buckRestrictionSortBy = function () {
					return function (object) {
						return (
							object.bucketRestriction &&
							object.bucketRestriction.bkt &&
							object.bucketRestriction.bkt.Id
						);
					};
				};

				vm.getCategoryMetadata = (category) =>
					getMetadataForCategory($scope.root.categoryMetadata, category);

				vm.categoryClass = function (category) {
					return category
						? category.toLowerCase().replace(/\s/g, '_').replace('&', 'and')
						: '';
				};

				// The 'item-ignored' class grays out ignored items, but it should only
				// be added if a user can use the toggle button to change whether an
				// item is ignored. Else, we're in a rules based model, and ignored is
				// controled by the SET/UNSET buttons
				vm.shouldGrayOut = (item) => {
					if (item.bucketRestriction) {
						return item.bucketRestriction?.ignored && vm.showToggleIgnored();
					} else if (item.segmentMemberRestriction) {
						return (
							item.segmentMemberRestriction?.ignored && vm.showToggleIgnored()
						);
					}
					return false;
				};

				vm.disableAllRestrictions = function () {
					return getPublicProperty('disableAllTreeRestrictions');
				};

				vm.showSubCategory = () => {
					if (!vm.tree.bucketRestriction || !vm.item) {
						return false;
					}
					const {entityType} = vm.tree.bucketRestriction;
					const {Subcategory} = vm.item;
					const isActivity = !!entityType;
					return isActivity || Subcategory !== 'Other';
				};

				vm.handleScopeChange = (key, value) => {
					vm[key] = value;
					vm.root.saveState();
					vm.root.setTotalRuleCount();
				};

				vm.getBackgroundColor = (tree, item) => {
					if (vm.shouldGrayOut(tree)) {
						return '#aaa';
					} else if (item?.Category){
						const categoryMetadata = vm.getCategoryMetadata(item.Category);
						return vm.root.getCategoryThemeColor(categoryMetadata, item);
					}
				};

				vm.mouseOverHighlight = (id) => {
					if (document.getElementById('input-' + id)) {
						document.getElementById('input-' + id).classList.add('active-link');
					}
				};

				vm.mouseOutHighlight = (id) => {
					if (document.getElementById('input-' + id)) {
						document.getElementById('input-' + id).classList.remove('active-link');
					}
				};
				vm.init();
			},
		};
	});
