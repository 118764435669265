import React from 'common/react-vendor';
import {
	DNBButton,
	DNBCard,
	DNBDivider,
	DNBSelect,
	DNBSelectOption,
	DNBTag,
	DNBTypography,
} from 'common/dnb-uux-vendor';
import {QueryState} from 'common/stores/query/types';
import {useSelector} from 'react-redux';
import classNames from 'classnames';
import {
	getCategoryThemeColor,
	getMetadataForCategory,
} from 'common/components/datacloud/datacloud.service.vanilla';
import {isRulesBasedModelMode} from 'common/stores/query/helpers';
import {Attribute, AttributeEntity} from '../tree/types/Attribute.types';
import {LogicalRestriction, Restriction} from '../../query.types';
import {
	IQueryBuilderExtends,
	IQueryBuilderList,
	IQueryBuilderListCard,
} from './QueryBuilderListTypes';
import SvgUndo from '../../../../../../atlas/assets/svgrs/segmentation/Undo';
import {Operator} from '../../query.enums';

const renderItem = (
	props: IQueryBuilderList,
	isSegmentMember: boolean
): React.ReactElement => {
	const {root, tree} = props;
	const {bucketRestriction, segmentMemberRestriction} = tree;
	const {
		enrichments,
		eventEnrichments,
		enrichmentsMap,
		eventEnrichmentsMap,
		showTimeSeries,
		categoryMetadata,
		showSegmentationV2,
		segmentsList,
		getBucketLabel,
		listItemClickCollapsed,
	} = root;
	const getItem = (): Attribute | undefined | null => {
		if (isSegmentMember) {
			if (showSegmentationV2 && segmentMemberRestriction) {
				const segment = segmentsList.find(
					({name}) => name === segmentMemberRestriction.segmentName
				);
				return {
					Entity: AttributeEntity.AccountSegmentMember,
					Category: `${segment?.dataType} ${
						segment?.type === 'List' ? '' : 'Segments'
					}`,
					DisplayName: segment?.display_name,
					Subcategory: '',
				} as Attribute;
			}
		}
		if (bucketRestriction) {
			if (
				showTimeSeries &&
				!Object.keys(enrichmentsMap).includes(bucketRestriction.attr)
			) {
				const {entityType, attr} = bucketRestriction;
				if (!entityType) {
					return;
				}
				const [, colId] = attr.split('.');
				const newAttr = `${entityType}.${colId}`;
				if (!Object.keys(eventEnrichmentsMap).includes(newAttr)) {
					return;
				}
				const index = eventEnrichmentsMap[newAttr] || 0;
				return eventEnrichments[index];
			}
			const index = enrichmentsMap[bucketRestriction.attr] || 0;
			return enrichments[index];
		}
	};
	const item = getItem() || ({} as Attribute);
	const {Category, DisplayName, Subcategory} = item;
	const categorydata = getMetadataForCategory(categoryMetadata, Category);
	const id = getBucketLabel(tree, false);
	const mouseOverHighlight = (id: number): void => {
		document
			.getElementById(`bucket-${id}`)
			?.parentElement?.classList.add('active-link');
	};

	const mouseOutHighlight = (id: number): void => {
		document
			.getElementById(`bucket-${id}`)
			?.parentElement?.classList.remove('active-link');
	};
	const color = getCategoryThemeColor(categorydata, item);
	const title = isSegmentMember
		? `${Category} - ${DisplayName}`
		: `${
				categorydata?.displayName || Category
		  } - ${Subcategory} - ${DisplayName}`;
	const ignored = isSegmentMember
		? segmentMemberRestriction?.ignored
		: bucketRestriction?.ignored;
	return (
		<DNBTypography
			component='span'
			className={classNames({
				'query-list-number-redesign': true,
				'noselect-children': true,
				'ignored': ignored,
			})}
			title={title}>
			<DNBTag
				type='mild-scale'
				size='small'
				id={`input-${id}`}
				sx={{
					'padding': '2px 8px',
					'verticalAlign': 'baseline',
					'bgcolor': ignored ? '#aaa' : color,
					':focus': {
						bgcolor: ignored ? '#aaa' : color,
						outline: 'unset',
					},
					':hover': {
						bgcolor: ignored ? '#aaa' : color,
					},
				}}
				onClick={() => listItemClickCollapsed(props)}
				label={
					<DNBTypography component='span' variant='caption'>
						{id}
					</DNBTypography>
				}
				onMouseOver={() => mouseOverHighlight(id)}
				onMouseOut={() => mouseOutHighlight(id)}
			/>
		</DNBTypography>
	);
};

const renderOperator = (operator: string): React.ReactElement => {
	return (
		<DNBTypography
			component='div'
			variant='compact-body'
			className={`query-list-operator label-operator item-operator-${operator}`}>
			{operator}
		</DNBTypography>
	);
};

const renderOperatorSection = (
	logicalRestriction: LogicalRestriction | undefined,
	root: IQueryBuilderExtends
): React.ReactElement => {
	return logicalRestriction?.operator ? (
		<div className='query-section-operator-dropdown query-list-operator noselect-children'>
			<DNBSelect<string>
				value={logicalRestriction?.operator}
				size='compact'
				onChange={(_, value) => {
					if (value !== null)
						root.listItemClickOperator(logicalRestriction, value);
				}}>
				{Object.keys(Operator).map((key) => (
					<DNBSelectOption key={key} value={key}>
						{key}
					</DNBSelectOption>
				))}
			</DNBSelect>
		</div>
	) : (
		<></>
	);
};

const ListItem = (props: IQueryBuilderList): React.ReactElement => {
	const {root, tree, parent} = props;
	const {bucketRestriction, segmentMemberRestriction} = tree;
	const canEdit = (parent: LogicalRestriction | Restriction): boolean => {
		if (parent?.logicalRestriction?.canNotEditOperator) {
			return true;
		}
		const level = parent?.logicalRestriction?.restrictions?.filter((r) => {
			const innerLevel = r.logicalRestriction?.restrictions.filter(
				(inner) => inner.segmentMemberRestriction
			);
			return innerLevel ? innerLevel.length > 0 : false;
		});
		return level ? level.length > 0 : false;
	};
	let i = 0;
	return (
		<>
			{bucketRestriction && renderItem(props, false)}
			{segmentMemberRestriction && (
				<>
					{renderItem(props, true)}
					<span className='query-list-operator noselect-children'>
						{renderOperator('and')}
						{renderOperator('or')}
					</span>
				</>
			)}
			{bucketRestriction &&
				renderOperatorSection(parent?.logicalRestriction, root)}
			{(tree.logicalRestriction?.restrictions || []).length > 0 && (
				<>
					<DNBTypography
						component='span'
						variant='compact-medium'
						className='query-list-parenthesis'>
						(
					</DNBTypography>
					<ul className='query-list'>
						{tree.logicalRestriction?.restrictions.map((innerTree) => {
							return (
								(innerTree.bucketRestriction?.bkt ||
									innerTree.segmentMemberRestriction ||
									innerTree.activityRestriction ||
									innerTree.logicalRestriction) && (
									<li
										key={innerTree.$$hashKey || i++}
										className={classNames({
											[`query-list-type-${tree.logicalRestriction?.operator.toLowerCase()}`]:
												tree.logicalRestriction?.operator,
											'input-section-item-box-subtree':
												innerTree.logicalRestriction?.restrictions,
										})}>
										<ListItem
											tree={
												innerTree.activityRestriction
													? (innerTree.activityRestriction
															.restriction as Restriction)
													: innerTree
											}
											parent={tree}
											root={root}
										/>
									</li>
								)
							);
						})}
					</ul>
					<DNBTypography
						component='span'
						variant='compact-medium'
						className='query-list-parenthesis query-list-section-end'>
						)
					</DNBTypography>
					{canEdit(parent) && (
						<span
							className={classNames({
								'query-list-operator': true,
								'query-list-operator-alternate': true,
								'noselect-children': true,
								'input-section-all':
									tree.logicalRestriction?.operator === 'AND',
								'input-section-any': tree.logicalRestriction?.operator === 'OR',
							})}>
							{renderOperator('and')}
							{renderOperator('or')}
						</span>
					)}
					{!canEdit(parent) &&
						renderOperatorSection(parent?.logicalRestriction, root)}
				</>
			)}
		</>
	);
};

const QueryBuilderListCard = (
	props: IQueryBuilderListCard
): React.ReactElement => {
	const {scope} = props;
	const refresh = useSelector(
		(state: {query: QueryState}) => state?.query?.public.refresh
	);
	const {history = []} = scope;
	const isRules = isRulesBasedModelMode();
	const trees = isRules
		? scope.getRulesInputTree()
		: scope.getSegmentInputTree();
	let i = 0;
	return (
		<DNBCard
			sx={{
				p: '18px 12px',
				borderRadius: '8px',
				display: 'flex',
				justifyContent: 'space-between',
				minHeight: '72px',
				alignItems: 'center',
				width: '100%',
			}}
			hasBorder>
			<div className='query-list-display'>
				<ul className='query-list query-list-root'>
					{trees?.map((tree) => {
						return (
							<li
								key={i++}
								className={classNames({
									[`query-list-type-${tree.logicalRestriction?.operator.toLowerCase()}`]:
										tree.logicalRestriction?.operator,
									'input-section-item-box-subtree':
										tree.logicalRestriction?.restrictions,
									'modifiedCounts': refresh,
								})}>
								{(tree.activityRestriction ||
									(tree.logicalRestriction?.restrictions || []).length > 0) && (
									<ListItem
										tree={
											tree.activityRestriction
												? (tree.activityRestriction.restriction as Restriction)
												: tree
										}
										parent={tree}
										root={scope}
									/>
								)}
							</li>
						);
					})}
				</ul>
			</div>
			<DNBDivider
				sx={{
					borderWidth: '1px',
					borderRadius: '4px',
					height: '40px',
				}}
				className='query-builder-list-hr'
				orientation='horizontal'
				variant='lightIncidental'
			/>
			<DNBButton
				variant='text'
				size='compact'
				className='query-list-undo'
				onClick={() => scope.clickUndo()}
				disabled={history?.length === 0 || isRules}
				startIcon={<SvgUndo />}>
				Undo
			</DNBButton>
		</DNBCard>
	);
};

export {QueryBuilderListCard};
