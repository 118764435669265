import React from 'common/react-vendor';
import {DNBButton, DNBTooltip, DNBTypography} from 'common/dnb-uux-vendor';
import {isEmpty} from 'lodash';

const DEFAULT_FONTSIZE = 14;
const CLIP_SIZE = 22;
const ACTION_WIDTH = 70;

interface EnumAttributeProps {
	id: string;
	values: string[];
	suffix?: string;
}

const getWidth = (id: string): number => {
	const bucket = document.getElementById(`bucket-${id}`)?.parentElement;
	const targetRoot = bucket?.querySelector('.query-section-item-displayname');
	const namePart = targetRoot?.querySelector(
		'.query-section-item-name'
	) as HTMLElement;
	const operationPart = targetRoot?.querySelector(
		'.query-section-item-operation .operation-label'
	);
	const width =
		(targetRoot?.clientWidth || 0) -
		(namePart?.offsetWidth || 0) -
		(operationPart?.clientWidth || 0);
	return width;
};

const EnumAttribute = ({
	id,
	values,
	suffix,
}: EnumAttributeProps): React.ReactElement => {
	const ref = React.useRef<HTMLDivElement>(null);
	const [showAction, setShowAction] = React.useState(false);
	const [counts, setCounts] = React.useState(0);
	const [attributeWidth, setattributeWidth] = React.useState(0);
	React.useLayoutEffect(() => {
		if (ref.current) {
			const width = getWidth(id) - ACTION_WIDTH;
			setattributeWidth(width);
			let attributeDisplayCounts = 0;
			const {children} = ref.current;
			const len = children.length;
			for (let i = 0; i < len; i++) {
				const node = children?.[i] as HTMLElement;
				if (node.offsetLeft < width) {
					attributeDisplayCounts++;
				}
			}
			setCounts(attributeDisplayCounts);
			setShowAction(values.length > attributeDisplayCounts);
		}
	}, [ref, id, values?.length]);
	if (isEmpty(values) || isEmpty(values.toLocaleString())) {
		return <></>;
	}
	const total = values.length;
	const nodeId = `enumAttribute-${id}`;
	return (
		<DNBTypography
			component='div'
			variant='compact-bold'
			sx={{
				display: 'flex',
				alignItems: 'center',
				justifyContent: 'space-between',
				width: `${attributeWidth / DEFAULT_FONTSIZE + 1}em`,
			}}>
			<div
				id={nodeId}
				ref={ref}
				style={{
					maxWidth: `${
						attributeWidth / DEFAULT_FONTSIZE + total > counts ? -1 : 1
					}em`,
					overflow: 'hidden',
					textOverflow: 'ellipsis',
					whiteSpace: 'nowrap',
				}}>
				{values
					.filter((_, i) => i < 100)
					.map((value, index) => {
						const valueLen = value.length;
						let tmp = value;
						if (valueLen > CLIP_SIZE) {
							tmp = `${value.substring(0, CLIP_SIZE)}...`;
						}
						const element = (
							<span key={value}>
								<DNBTooltip content={value} placement='top' arrow>
									<span>
										{tmp}
										{total - 1 !== index && <>{`${suffix} `}</>}
									</span>
								</DNBTooltip>
							</span>
						);
						return element;
					})}
			</div>
			{showAction && total > counts && (
				<DNBButton
					size='small'
					sx={{lineHeight: 1, padding: 0, cursor: 'pointer'}}
					variant='text'
					className='action'>
					+{total - counts}
				</DNBButton>
			)}
		</DNBTypography>
	);
};

/**
 * TODO: Angular directive components can't use camelCase attributes...
 * Since we are migrating everything to React I feel that ideally
 * we should keep using camelCases for props, that is why I am creating this temporary
 * wrapper to pass the angular attributes to the React component...
 *
 * DONT use this component!
 * Use EnumAttribute if you are refactoring an angular component to React.
 * This component purpose is only to be use in the temporary
 * enum-attribute.component react2angular
 *
 * TODO: DELETE ME Once all instances of <enum-attribute />
 * are removed...
 */

// eslint-disable-next-line eslint-comments/disable-enable-pair
/* eslint-disable  @typescript-eslint/no-explicit-any */
const EnumAttributeWithAngularProps = ({
	id,
	values,
	suffix,
}: Record<string, any>): React.ReactElement => (
	<EnumAttribute id={id} values={values} suffix={suffix} />
);

export {EnumAttribute, EnumAttributeWithAngularProps};
