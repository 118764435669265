import {entities} from 'common/components/datacloud/query/query.constants';
import {QueryActionType} from './enums';
import {defaultMode} from './constants';
import type {QueryAction, QueryState, RestrictionType} from './types';

const ENTITY_RESTRICTION_MAP = new Map<string, RestrictionType>([
	['account', 'accountRestriction'],
	['contact', 'contactRestriction'],
	['member', 'memberRestriction'],
]);

const initialRestrictions = (): Partial<QueryState> =>
	entities.reduce((restrictions, entity) => {
		const key = ENTITY_RESTRICTION_MAP.get(entity)!;

		return {
			...restrictions,
			[key]: {
				restriction: {
					logicalRestriction: {
						operator: 'AND',
						restrictions: [],
					},
				},
			},
		};
	}, {} as Partial<QueryState>);

const initialState: QueryState = {
	segment: null,
	segments: [],
	history: [],
	mode: defaultMode,
	counts: {
		accounts: {
			value: 0,
			loading: true,
		},
		contacts: {
			value: 0,
			loading: true,
		},
		unknowncontacts: {
			value: 0,
			loading: false,
		},
		product: {
			value: 0,
			loading: true,
		},
	},
	addBucketTreeType: '',
	public: {
		enableSaveSegmentButton: false,
		enableExportSegmentButton: true,
		resetLabelIncrementor: false,
		disableAllTreeRestrictions: false,
		refresh: 0,
	},
	collectionStatus: null,
	cancelUpdateBucketCalls: false,
	selectedBucket: 'A',
	...initialRestrictions(),
};

const queryReducer = (
	state = initialState,
	action: QueryAction
): QueryState => {
	switch (action.type) {
		case QueryActionType.CLEAR_QUERY_STORE:
			return {
				...initialState,
			};
		case QueryActionType.SET_QUERY_PROPERTY_VALUE:
			return {
				...state,
				[action.payload.key]: action.payload.value,
			};
		case QueryActionType.SET_QUERY_PUBLIC_VALUE:
			return {
				...state,
				public: {
					...state.public,
					[action.payload.key]: action.payload.value,
				},
			};
		case QueryActionType.SET_ADD_BUCKET_TREE_ROOT:
			if (action.payload.tree === null) {
				const newState = {...state};

				delete newState.addBucketTreeRoot;

				return {
					...newState,
				};
			}

			return {
				...state,
				addBucketTreeRoot: action.payload.tree, // this causes PLS-6617
				addBucketTreeType: action.payload.type ? action.payload.type : '',
			};
		case QueryActionType.SET_COUNT_LOADING:
			return {
				...state,
				counts: {
					...state.counts,
					[action.payload.key]: {
						...state.counts[action.payload.key],
						loading: action.payload.value,
					},
				},
			};
		default:
			return state;
	}
};

export {queryReducer};
