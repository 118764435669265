import {LogicalRestrictionRecord} from 'common/components/datacloud/query/query.types';
import {QueryActionType} from './enums';
import type {QueryAction, QueryState, QueryPublic, QueryCounts} from './types';

const clearQueryStore = (): QueryAction => {
	return {
		type: QueryActionType.CLEAR_QUERY_STORE,
	};
};

const setQueryPropertyValue = <QueryValue>(
	key: keyof QueryState,
	value: QueryValue
): QueryAction => {
	return {
		type: QueryActionType.SET_QUERY_PROPERTY_VALUE,
		payload: {
			key,
			value,
		},
	};
};

const setQueryPublicValue = (
	key: keyof QueryPublic,
	value: boolean | number
): QueryAction => {
	return {
		type: QueryActionType.SET_QUERY_PUBLIC_VALUE,
		payload: {
			key,
			value,
		},
	};
};

const setAddBucketTreeRoot = (
	tree: LogicalRestrictionRecord | null,
	type?: string
): QueryAction => {
	return {
		type: QueryActionType.SET_ADD_BUCKET_TREE_ROOT,
		payload: {
			tree,
			type,
		},
	};
};

const setCountLoading = (
	key: keyof QueryCounts,
	value: boolean
): QueryAction => {
	return {
		type: QueryActionType.SET_COUNT_LOADING,
		payload: {
			key,
			value,
		},
	};
};

export {
	clearQueryStore,
	setQueryPropertyValue,
	setQueryPublicValue,
	setAddBucketTreeRoot,
	setCountLoading,
};
