import {isEmpty, isNil} from 'lodash';
import {EntityMapping} from 'components/datacloud/datacloud.enums';
import {getDataCloudProperty, getEnrichments} from 'common/stores/datacloud';
import {isSegmentationV2Enabled} from 'common/stores/tenantConfig';
import type {Cube, SubType} from './tree.types';
import type {
	ActivityRestriction,
	Bkt,
	Bucket,
	BucketRestriction,
	Val,
	Vals,
} from '../../query.types';
import {
	BucketCmp,
	BucketType,
	Entity,
	Period,
	TreeType,
} from '../../query.enums';
import {
	cmpMap,
	twoInputs,
	noInputs,
	chipInputs,
	SegmentMember,
} from './tree.constants';
import {
	changePurchaseHistoryBooleanValue,
	changePurchaseHistoryCmp,
	changePurchaseHistoryTimeframePeriod,
	changePurchaseHistoryValue,
	getPurchaseHistoryAttributeRules,
	getPurchaseHistoryBktValues,
	getPurchaseHistoryBooleanModel,
	getPurchaseHistoryBucketCmp,
	getPurchaseHistoryOperationLabel,
	getPurchaseHistoryPeriodValue,
	getPurchaseHistoryValue,
	getPurchaseHistoryValues,
	getPurchaseHistoryValuesBasedOnPosition,
	removePurchaseHistoryKey,
	resetPurchaseHistoryBktValues,
	showPurchaseHistoryType,
} from './tree-purchase-history.helpers';
import {AttributeEntity} from './types';
import {EntityMappingResponse} from '../../../datacloud.types';
import {getCountByQuery} from '../../queryService.queries';

// #region getEntity
type GetEntityReturn<EntityReturn> = EntityReturn extends AttributeEntity
	? AttributeEntity
	: Entity;

const getEntity = <EntityReturn>(
	bucketRestriction?: BucketRestriction | ActivityRestriction
): GetEntityReturn<EntityReturn> => {
	const unknownEntity = Entity.Unknown as GetEntityReturn<EntityReturn>;

	if (bucketRestriction) {
		const {attr} = bucketRestriction;

		if (attr) {
			const [entity] = attr.split('.');

			return entity as GetEntityReturn<EntityReturn>;
		}

		return unknownEntity;
	}

	console.warn('Bucket Restriction not existing', bucketRestriction);

	return unknownEntity;
};
// #region getEntity
const getAttrName = (
	bucketRestriction?: BucketRestriction | ActivityRestriction
): string | undefined => {
	if (bucketRestriction) {
		const {attr} = bucketRestriction;

		if (attr) {
			const [, AttrName] = attr.split('.');

			return AttrName;
		}
	}
	return undefined;
};

const isPurchaseHistory = (bucketRestriction?: BucketRestriction): boolean => {
	const entity = getEntity(bucketRestriction);

	return entity === Entity.PurchaseHistory;
};

const isContact = (bucketRestriction?: BucketRestriction): boolean => {
	const entity = getEntity<AttributeEntity>(bucketRestriction);

	const contactEntities = [
		AttributeEntity.Contact,
		AttributeEntity.ContactMarketingActivity,
		AttributeEntity.CuratedContact,
	];

	return contactEntities.includes(entity);
};

// #region showType
interface ShowTypeParams {
	bucketRestriction: BucketRestriction;
	type: TreeType & BucketType;
	typeToShow: BucketType;
}

/**
 * Return if a type ('Boolean', 'Numerical', 'Enum') can be shown or not
 */
const showType = ({
	bucketRestriction,
	type,
	typeToShow,
}: ShowTypeParams): boolean => {
	if (isPurchaseHistory(bucketRestriction)) {
		return showPurchaseHistoryType({bucketRestriction, type, typeToShow});
	}

	switch (typeToShow) {
		case BucketType.Numerical:
		case BucketType.Boolean:
		case BucketType.Enum:
		case BucketType.Date:
		case BucketType.String:
			return typeToShow === type;
		default:
			return false;
	}
};
// #endregion showType

const showTo = (bucketRestriction: BucketRestriction): boolean =>
	bucketRestriction.bkt?.Cmp !== undefined &&
	twoInputs.includes(bucketRestriction.bkt.Cmp);

// #region setValuesBasedOnPosition
/**
 * This helper is being used for numeric ranges
 */
interface SetValuesBasedOnPositionParams {
	entity?: Entity;
	cmp?: BucketCmp;
	values?: Vals;
	index: number;
	value: Val;
}

/**
 * TODO: once the service that consumes this function
 * gets refactor, change the function to return
 * a new object instead of reassigning the parameters
 * and remove the lint skip
 */
const setValuesBasedOnPosition = ({
	entity,
	cmp,
	values = [],
	index,
	value,
}: SetValuesBasedOnPositionParams): void => {
	const purchaseHistoryBucketCmps = [BucketCmp.BETWEEN, BucketCmp.BETWEEN_DATE];

	if (
		entity === Entity.PurchaseHistory &&
		cmp &&
		purchaseHistoryBucketCmps.includes(cmp)
	) {
		// Disabled because mutating the argument is the entire purpose of this function
		// eslint-disable-next-line no-param-reassign
		values[index] = value;
		return;
	}

	switch (cmp) {
		case BucketCmp.GT_AND_LT:
		case BucketCmp.GT_AND_LTE:
		case BucketCmp.GTE_AND_LT:
		case BucketCmp.GTE_AND_LTE:
			// Disabled because mutating the argument is the entire purpose of this function
			// eslint-disable-next-line no-param-reassign
			values[index] = value;
			break;
		default:
			// Disabled because mutating the argument is the entire purpose of this function
			// eslint-disable-next-line no-param-reassign
			values[0] = value;
			break;
	}
};
// #endregion setValuesBasedOnPosition

// #region getValuesBasedOnPosition
type GetValuesBasedOnPositionReturn = Val | null;

interface LastOrEmptyParams {
	values: Vals;
	index: number;
}

interface GetLastValueReturn {
	isLastOrEmpty: boolean;
	lastValue?: number | string;
}

const getLastValue = ({
	values = [],
	index,
}: LastOrEmptyParams): GetLastValueReturn => {
	const valuesLength = values.length;
	const lastIndex = valuesLength - 1;

	const isLastOrEmpty = index === lastIndex || valuesLength === 0;

	const lastValue = values[lastIndex];

	return {
		isLastOrEmpty,
		lastValue,
	};
};

const getLastValueOrNull = (
	params: LastOrEmptyParams
): GetValuesBasedOnPositionReturn => {
	const {isLastOrEmpty, lastValue} = getLastValue(params);

	if (isLastOrEmpty) {
		return lastValue;
	}

	return null;
};

const getNullOrLastValue = (
	params: LastOrEmptyParams
): GetValuesBasedOnPositionReturn => {
	const {isLastOrEmpty, lastValue} = getLastValue(params);

	if (isLastOrEmpty) {
		return null;
	}

	return lastValue;
};

interface GetValuesBasedOnPositionParams {
	entity: Entity;
	cmp?: BucketCmp;
	values?: Vals;
	index: number;
}

const getValuesBasedOnPosition = ({
	entity,
	cmp,
	values,
	index,
}: GetValuesBasedOnPositionParams): GetValuesBasedOnPositionReturn => {
	const purchaseHistoryCases = [
		BucketCmp.BETWEEN,
		BucketCmp.BETWEEN_DATE,
		BucketCmp.EQUAL,
		BucketCmp.NOT_EQUAL,
		BucketCmp.AFTER,
		BucketCmp.BEFORE,
		BucketCmp.WITHIN,
		BucketCmp.PRIOR_ONLY,
	];

	if (
		entity === Entity.PurchaseHistory &&
		cmp &&
		purchaseHistoryCases.includes(cmp)
	) {
		return getPurchaseHistoryValuesBasedOnPosition({
			cmp,
			values,
			index,
		});
	}

	switch (cmp) {
		case BucketCmp.GT_AND_LT:
		case BucketCmp.GT_AND_LTE:
		case BucketCmp.GTE_AND_LTE:
		case BucketCmp.GTE_AND_LT:
			if (!values) {
				return;
			}

			return values[index];
		case BucketCmp.GREATER_THAN:
		case BucketCmp.GREATER_OR_EQUAL:
		case BucketCmp.NOT_EQUAL:
		case BucketCmp.EQUAL:
			return getLastValueOrNull({values, index});
		case BucketCmp.LESS_THAN:
		case BucketCmp.LESS_OR_EQUAL:
			return getNullOrLastValue({values, index});
		default:
			return null;
	}
};
// #endregion getValuesBasedOnPosition

// #region getOperationLabel
/**
 * Return the operation label for and Account or Contacts Entity
 */
interface GetOperationLabelParams {
	type: TreeType & BucketType;
	bucketRestriction: BucketRestriction;
}

const getOperationLabel = ({
	type,
	bucketRestriction,
}: GetOperationLabelParams): undefined | string => {
	if (isNil(bucketRestriction.bkt)) {
		return;
	}

	const cmp = bucketRestriction.bkt.Cmp;

	const purchaseHistoryCases = [
		TreeType.TimeSeries,
		TreeType.PercentChange,
		BucketType.Enum,
	];

	if (
		isPurchaseHistory(bucketRestriction) &&
		purchaseHistoryCases.includes(type)
	) {
		return getPurchaseHistoryOperationLabel({
			type,
			bucketRestriction,
		});
	}

	switch (type) {
		case BucketType.Boolean:
		case BucketType.Numerical:
			if (!cmp) {
				return;
			}

			return cmpMap[cmp];
		case BucketType.String:
		case BucketType.Enum:
			switch (cmp) {
				case BucketCmp.EQUAL:
				case BucketCmp.IN_COLLECTION:
					return cmpMap.IN_COLLECTION;
				case BucketCmp.NOT_EQUAL:
				case BucketCmp.NOT_IN_COLLECTION:
					return cmpMap.NOT_IN_COLLECTION;
				default:
					if (!cmp) {
						return;
					}

					return cmpMap[cmp];
			}
		case BucketType.Date:
			return 'in timeframe';
		default:
			return 'has a value of';
	}
};
// #endregion getOperationLabel

// #region getBooleanValue
const getBooleanValue = (
	bucketRestriction: BucketRestriction
): string | boolean => {
	if (isPurchaseHistory(bucketRestriction)) {
		if (bucketRestriction.bkt?.Txn) {
			return !bucketRestriction.bkt.Txn.Negate;
		}

		return 'Empty';
	}

	const values = bucketRestriction.bkt?.Vals;
	const [firstValue] = values || [''];

	if (firstValue === 'Yes') {
		return 'True';
	}
	if (firstValue === 'No') {
		return 'False';
	}

	return '';
};
// #endregion getBooleanValue

// #region getNumericalValue
const getNumericalValue = (
	bucketRestriction: BucketRestriction,
	index?: number
): string | undefined => {
	if (isNil(index)) {
		return;
	}

	if (isPurchaseHistory(bucketRestriction) && !bucketRestriction.ignored) {
		const chgValues = bucketRestriction.bkt?.Chg?.Vals || [];

		if (!isEmpty(chgValues)) {
			return chgValues[index]?.toLocaleString();
		}
	}

	const values = bucketRestriction.bkt?.Vals;

	if (!isEmpty(values)) {
		return values![index]?.toLocaleString();
	}
};
// #endregion getNumericalValue

// #region getEnumValues
const getEnumValues = (
	bucketRestriction: BucketRestriction
): Vals | undefined => {
	if (isPurchaseHistory(bucketRestriction) && bucketRestriction.ignored) {
		return;
	}

	return bucketRestriction.bkt?.Vals || [];
};
// #endregion getEnumValues

// #region getStringValue
const getStringValue = (
	bucketRestriction: BucketRestriction
): Vals | string => {
	const cmp = bucketRestriction.bkt?.Cmp;
	if (cmp === BucketCmp.IS_NULL || cmp === BucketCmp.IS_NOT_NULL) {
		return '';
	}

	return bucketRestriction.bkt?.Vals;
};
// #endregion getStringValue

// #region getDateValue
const getDateValue = (
	bucketRestriction: BucketRestriction,
	index = 0
): string | number => {
	if (isPurchaseHistory(bucketRestriction)) {
		return 'Ever';
	}

	const cmp = bucketRestriction.bkt?.Fltr?.Cmp;

	if (cmp === BucketCmp.IS_NULL || cmp === BucketCmp.IS_NOT_NULL) {
		return '';
	}

	const values = bucketRestriction.bkt?.Fltr?.Vals || [];

	return values[index] || 'any (*)';
};
// #endregion getDateValue

// #reegion getOperationValue
/**
 * Return the value of the bucket restriction
 */
interface GetOperationValueParams {
	bucketRestriction: BucketRestriction;
	type: BucketType;
	index?: number;
}

type GetOperationValueReturn = Vals | string | boolean | undefined | number;

const getOperationValue = ({
	bucketRestriction,
	type,
	index,
}: GetOperationValueParams): GetOperationValueReturn => {
	switch (type) {
		case BucketType.Boolean: {
			return getBooleanValue(bucketRestriction);
		}
		case BucketType.Numerical: {
			return getNumericalValue(bucketRestriction, index);
		}
		case BucketType.Enum: {
			return getEnumValues(bucketRestriction);
		}
		case BucketType.String: {
			return getStringValue(bucketRestriction);
		}
		case BucketType.Date: {
			return getDateValue(bucketRestriction, index);
		}
		default:
			return 'Unknown';
	}
};
// #endregion getOperationValue

// #region getAttributeRules
interface IsSameAttributeAndBucketParams {
	bktValues: Vals;
	bucketValues: Vals;
	bktCmp?: BucketCmp;
	bucketCmp?: BucketCmp;
	isSameAttribute: boolean;
}

const isSameAttributeAndBucket = ({
	bktValues,
	bucketValues,
	bktCmp,
	bucketCmp,
	isSameAttribute,
}: IsSameAttributeAndBucketParams): boolean => {
	const [firstBkt, secondBkt] = bktValues || [];
	const [firstBucket, secondBucket] = bucketValues || [];

	const isSameBucket =
		firstBkt === firstBucket &&
		secondBkt === secondBucket &&
		bktCmp === bucketCmp;

	return isSameAttribute && isSameBucket;
};

interface GetAttributeRulesParams {
	bucketRestriction: BucketRestriction;
	bkt?: Bkt;
	bucket?: Bkt;
	isSameAttribute: boolean;
}

const getAttributeRules = ({
	bucketRestriction,
	bkt,
	bucket,
	isSameAttribute,
}: GetAttributeRulesParams): boolean => {
	if (isNil(bucket) || isNil(bkt)) {
		return isSameAttribute;
	}

	if (isPurchaseHistory(bucketRestriction)) {
		return getPurchaseHistoryAttributeRules({
			bkt,
			bucket,
			isSameAttribute,
		});
	}

	if (!isNil(bucket.Vals) && !isNil(bkt.Vals)) {
		const bktValues = bkt.Vals;
		const bucketValues = bucket.Vals;

		const bktCmp = bkt.Cmp;
		const bucketCmp = bucket.Cmp;

		return isSameAttributeAndBucket({
			bktValues,
			bucketValues,
			bktCmp,
			bucketCmp,
			isSameAttribute,
		});
	}

	if (
		!isNil(bucket.Fltr) &&
		!isNil(bucket.Fltr.Vals) &&
		!isNil(bkt.Fltr) &&
		!isNil(bkt.Fltr.Vals)
	) {
		const bktValues = bkt.Fltr.Vals;
		const bucketValues = bucket.Fltr.Vals;

		const bktCmp = bkt.Fltr.Cmp;
		const bucketCmp = bucket.Fltr.Cmp;

		return isSameAttributeAndBucket({
			bktValues,
			bucketValues,
			bktCmp,
			bucketCmp,
			isSameAttribute,
		});
	}

	return isSameAttribute;
};
// #endregion getAttributeRules

// #region isBucketUsed
const isBucketUsed = (bucket: BucketRestriction): boolean => {
	if (isPurchaseHistory(bucket)) {
		return typeof bucket.bkt?.Id === 'number';
	}

	return !bucket.ignored;
};
// #endregion isBucketUsed

// #region getBktValues
type GetBktValuesReturn = Vals | string;

const getBktValues = (
	bucketRestriction?: BucketRestriction,
	type?: TreeType | BucketType
): GetBktValuesReturn => {
	if (isPurchaseHistory(bucketRestriction)) {
		return getPurchaseHistoryBktValues(bucketRestriction, type);
	}

	return bucketRestriction?.bkt?.Vals || [];
};
// #endregion getBktValues

// #region getBktValue
interface GetBktValueParams {
	bucketRestriction?: BucketRestriction;
	index: number;
}

const getBktValue = ({
	bucketRestriction,
	index,
}: GetBktValueParams): GetValuesBasedOnPositionReturn | string => {
	const entity = getEntity(bucketRestriction);
	const txn = bucketRestriction?.bkt?.Txn;

	if (entity === Entity.PurchaseHistory && !isNil(txn)) {
		if (!isNil(txn.Negate)) {
			return txn.Negate ? 'No' : 'Yes';
		}

		if (!isNil(txn.Qty) && !isNil(txn.Qty.Vals)) {
			return txn.Qty.Vals[index];
		}

		if (!isNil(txn.Amt) && !isNil(txn.Amt.Vals)) {
			return txn.Amt.Vals[index];
		}
	}

	return getValuesBasedOnPosition({
		entity,
		cmp: bucketRestriction?.bkt?.Cmp,
		values: bucketRestriction?.bkt?.Vals,
		index,
	});
};
// #endregion getBktValue

// #region getValue
interface GetValueParams {
	bucketRestriction?: BucketRestriction;
	type?: BucketType | TreeType;
	index: number;
	subType?: SubType;
}

const getValue = (
	params: GetValueParams
): GetValuesBasedOnPositionReturn | string => {
	if (!params.subType) {
		return getBktValue(params);
	}

	if (isPurchaseHistory(params.bucketRestriction)) {
		return getPurchaseHistoryValue(params);
	}
};
// #endregion getValue

// #region getValues
interface GetValuesParams {
	bucketRestriction?: BucketRestriction;
	type?: TreeType | BucketType;
	subType?: SubType | BucketType;
}

const getValues = ({
	bucketRestriction,
	type,
	subType,
}: GetValuesParams): GetBktValuesReturn => {
	if (subType === undefined) {
		return getBktValues(bucketRestriction, type);
	}

	if (isPurchaseHistory(bucketRestriction)) {
		return getPurchaseHistoryValues({bucketRestriction, type, subType});
	}

	if (type === BucketType.Date) {
		if (bucketRestriction?.bkt?.Fltr?.Cmp === BucketCmp.EVER) {
			return [];
		}

		return bucketRestriction?.bkt?.Fltr?.Vals || [];
	}

	return [];
};
// #endregion getValues

//* ******************* Editing mode *********************************/
// #region changeBooleanValue
const changeBooleanValue = (
	bucketRestriction: BucketRestriction,
	booleanValue: string
): BucketRestriction => {
	if (isPurchaseHistory(bucketRestriction)) {
		return changePurchaseHistoryBooleanValue(bucketRestriction, booleanValue);
	}

	const newBucketRestriction = {...bucketRestriction};

	if (booleanValue && newBucketRestriction.bkt?.Vals) {
		newBucketRestriction.bkt.Vals[0] = booleanValue;
	} else if (newBucketRestriction.bkt) {
		newBucketRestriction.bkt.Vals = [];
	}

	return newBucketRestriction;
};
// #endregion changeBooleanValue

const changeValues = (
	bucketRestriction: BucketRestriction,
	value: Vals
): BucketRestriction => {
	const newBucketRestriction = {...bucketRestriction};

	if (newBucketRestriction.bkt) {
		newBucketRestriction.bkt.Vals = value;
	}

	return newBucketRestriction;
};

// #region changeCmpValue
const changeCmp = (
	bucketRestriction: BucketRestriction,
	value?: BucketCmp
): BucketRestriction => {
	const newBucketRestriction = {...bucketRestriction};

	if (newBucketRestriction.bkt) {
		newBucketRestriction.bkt.Cmp = value;
	}

	return newBucketRestriction;
};

interface ChangeCmpValueParams {
	bucketRestriction?: BucketRestriction;
	type?: TreeType | BucketType;
	value?: BucketCmp;
	subType?: SubType;
}

const changeCmpValue = ({
	bucketRestriction,
	value,
}: ChangeCmpValueParams): BucketRestriction | undefined => {
	if (isNil(bucketRestriction)) {
		return;
	}

	if (isPurchaseHistory(bucketRestriction)) {
		/**
		 * The previous changeCmpValue only passed
		 * this two values to the purchase version...
		 */
		return changePurchaseHistoryCmp({
			bucketRestriction,
			type: value as TreeType & BucketType,
		});
	}

	return changeCmp(bucketRestriction, value);
};
// #endregion changeCmpValue

// #region changeBktValue
interface ChangeBktValueParams {
	bucketRestriction?: BucketRestriction;
	value: Val;
	index: number;
}

const changeBktValue = ({
	bucketRestriction,
	value,
	index,
}: ChangeBktValueParams): void => {
	const entity = getEntity(bucketRestriction);

	setValuesBasedOnPosition({
		entity,
		cmp: bucketRestriction?.bkt?.Cmp,
		values: bucketRestriction?.bkt?.Vals,
		index,
		value,
	});
};
// #endregion changeBktValue

// #region changeActivityNumber
interface ChangeActivityNumberParams {
	activityRestriction: ActivityRestriction;
	value: Val;
	index: number;
}

const changeActivityNumber = ({
	activityRestriction,
	value,
	index,
}: ChangeActivityNumberParams): void => {
	setValuesBasedOnPosition({
		cmp: activityRestriction.cmp,
		values: activityRestriction.cnt,
		index,
		value,
	});
};
// #endregion changeActivityNumber

// #region changeValue
interface ChangeValueParams {
	bucketRestriction?: BucketRestriction;
	type?: TreeType;
	value: Val;
	index: number;
	subType?: SubType;
}

const changeValue = ({
	bucketRestriction,
	type,
	value,
	index,
	subType,
}: ChangeValueParams): void => {
	if (subType === undefined) {
		changeBktValue({bucketRestriction, value, index});
	} else if (isPurchaseHistory(bucketRestriction)) {
		changePurchaseHistoryValue({
			bucketRestriction,
			type,
			value,
			index,
			subType,
		});
	}
};
// #endregion changeValue

// #region changeTimeframePeriod
interface ChangeTimeframePeriodParams {
	bucketRestriction?: BucketRestriction;
	type?: TreeType | BucketType;
	value: Bucket | Period;
}

const changeTimeframePeriod = ({
	bucketRestriction,
	type,
	value,
}: ChangeTimeframePeriodParams): BucketRestriction | undefined => {
	if (isNil(bucketRestriction)) {
		return;
	}

	if (isPurchaseHistory(bucketRestriction)) {
		return changePurchaseHistoryTimeframePeriod({
			bucketRestriction,
			type,
			value,
		});
	}

	if (type !== BucketType.Date) {
		throw new Error("Can't change timeframe on non-date buckets");
	}

	const newBucketRestriction = {...bucketRestriction};

	if (
		bucketRestriction &&
		newBucketRestriction.bkt &&
		typeof value === 'object'
	) {
		newBucketRestriction.bkt.Fltr!.Period = value.Period;
		newBucketRestriction.bkt.Fltr!.Vals = value.Vals;
	}

	return newBucketRestriction;
};
// #endregion changeTimeframePeriod

// #region resetBktValues
interface ResetBktValuesParams {
	bucketRestriction?: BucketRestriction;
	type?: TreeType;
	subType?: SubType;
}

const resetBktValues = ({
	bucketRestriction,
	...resetParams
}: ResetBktValuesParams): BucketRestriction | undefined => {
	if (isNil(bucketRestriction)) {
		return;
	}

	if (isPurchaseHistory(bucketRestriction)) {
		return resetPurchaseHistoryBktValues({
			bucketRestriction,
			...resetParams,
		});
	}

	const newBucketRestriction = {...bucketRestriction};

	if (newBucketRestriction.bkt) {
		newBucketRestriction.bkt.Vals = [];
	}

	return newBucketRestriction;
};
// #endregion resetBktValues

// #region removeKey
interface RemoveKeyParams {
	bucketRestriction?: BucketRestriction;
	type?: BucketType | TreeType;
	subType: SubType;
}

const removeKey = (params: RemoveKeyParams): BucketRestriction | undefined => {
	if (isPurchaseHistory(params.bucketRestriction)) {
		return removePurchaseHistoryKey(params);
	}

	throw new Error('Not implemented');
};
// #endregion removeKey

// #region getBooleanModel
/**
 * Return the var for ng-model in a boolean bucket restriction
 */
const getBooleanModel = (bucketRestriction: BucketRestriction): string => {
	if (isPurchaseHistory(bucketRestriction)) {
		return getPurchaseHistoryBooleanModel(bucketRestriction);
	}

	const [firstValue] = bucketRestriction.bkt?.Vals || [];
	const cmp = bucketRestriction.bkt?.Cmp;

	if (firstValue === BucketCmp.IS_NULL || cmp === BucketCmp.IS_NULL) {
		return 'Empty';
	}

	if (firstValue === BucketCmp.IS_NOT_NULL || cmp === BucketCmp.IS_NOT_NULL) {
		return 'Present';
	}

	return firstValue as string;
};
// #endregion getBooleanModel

// #region getCmp
/**
 * We need to return an empty string
 * when there is no value.
 * This is because the previous implementations
 * compares the return value against empty string
 * instead of making a falsy comparison...
 */
const getCmp = (bucketRestriction: BucketRestriction): BucketCmp | '' => {
	return bucketRestriction.bkt?.Cmp || '';
};
// #endregion getCmp

// #region getActivityNumber
const getActivityNumber = (
	activityRestriction: ActivityRestriction,
	index: number
): GetValuesBasedOnPositionReturn => {
	const entity = getEntity(activityRestriction);

	return getValuesBasedOnPosition({
		entity,
		cmp: activityRestriction.cmp,
		values: activityRestriction.cnt,
		index,
	});
};
// #endregion getActivityNumber

// #region getCubeBktList
const getCubeBktList = (cube: Cube): Cube['Bkts']['List'] => {
	return cube.Bkts.List;
};
// #endregion getCubeBktList

// #region getBucketCmp
interface GetBucketCmpParams {
	bucketRestriction?: BucketRestriction;
	type?: BucketType | TreeType;
	subType: SubType | BucketType;
}

/**
 * We need to return an empty string
 * when there is no value.
 * This is because the previous implementations
 * compares the return value against empty string
 * instead of making a falsy comparison...
 */
const getBucketCmp = ({
	bucketRestriction,
	type,
	subType,
}: GetBucketCmpParams): BucketCmp | '' => {
	if (isPurchaseHistory(bucketRestriction)) {
		return getPurchaseHistoryBucketCmp({bucketRestriction, type, subType});
	}

	const cmp = bucketRestriction?.bkt?.Cmp;

	if (type === BucketType.Date) {
		return bucketRestriction?.bkt?.Fltr?.Cmp || cmp || '';
	}

	return bucketRestriction?.bkt?.Cmp || '';
};
// #endregion getBucketCmp

// #region getPeriodValue
interface GetPeriodValueParams {
	bucketRestriction?: BucketRestriction;
	type?: BucketType | TreeType;
	subType: SubType;
}

const getPeriodValue = ({
	bucketRestriction,
	type,
	subType,
}: GetPeriodValueParams): Period | string => {
	if (isPurchaseHistory(bucketRestriction)) {
		return getPurchaseHistoryPeriodValue({bucketRestriction, type, subType});
	}

	const defaultPeriod = bucketRestriction?.bkt?.Period || '';

	if (type === BucketType.Date) {
		return bucketRestriction?.bkt?.Fltr?.Period || defaultPeriod;
	}

	return defaultPeriod;
};
// #endregion getPeriodValue

const showEmptyOption = (bucketRestriction: BucketRestriction): boolean =>
	!isPurchaseHistory(bucketRestriction);

const hasInputs = (
	type: BucketType,
	bucketRestriction: BucketRestriction
): boolean => {
	const bucketsToGetCmp = [
		BucketType.Enum,
		BucketType.String,
		BucketType.Numerical,
	];

	if (bucketsToGetCmp.includes(type)) {
		return !noInputs.includes(getCmp(bucketRestriction) as BucketCmp);
	}

	return !noInputs.includes(bucketRestriction.bkt?.Cmp as BucketCmp);
};

const changeNumericalCmpValue = (
	bucketRestriction: BucketRestriction,
	cmp: BucketCmp
): BucketRestriction => {
	const notChips = !chipInputs.includes(cmp);

	const notTwoInputs = !twoInputs.includes(cmp);

	const hasRangeVals =
		bucketRestriction.bkt?.Vals && bucketRestriction.bkt.Vals.length === 2;

	const newBucketRestriction = {...bucketRestriction};

	if (notChips && notTwoInputs && hasRangeVals) {
		newBucketRestriction.bkt?.Vals?.splice(1, 1);
	}

	return changeCmp(newBucketRestriction, cmp);
};

// #region updateBucketCount
const getTreeMode = (bucketRestriction: BucketRestriction): SegmentMember => {
	const showSegmentationV2 = isSegmentationV2Enabled();

	const entityList = getDataCloudProperty<EntityMappingResponse | null>(
		'entityList'
	);

	if (showSegmentationV2 && entityList) {
		const entity = getEntity(bucketRestriction);

		const entityMappingAccount = entityList[EntityMapping.Account];

		const hasSomeEntityAccount = entityMappingAccount.some(
			(item) => item === entity
		);

		if (hasSomeEntityAccount) {
			return SegmentMember.Account;
		}

		return SegmentMember.Contact;
	}

	return isContact(bucketRestriction)
		? SegmentMember.Contact
		: SegmentMember.Account;
};

const validAttribute = async (
	bucketRestriction: BucketRestriction
): Promise<boolean> => {
	const name = getAttrName(bucketRestriction);
	const entity = getEntity(bucketRestriction);
	const enrichments = await getEnrichments();
	const findAttribute = enrichments.filter(({AttrName, Entity}) => {
		return AttrName === name && Entity.includes(entity);
	});

	return findAttribute.length > 0;
};

const updateBucketCount = async (
	bucketRestriction: BucketRestriction,
	segmentName?: string
): Promise<number> => {
	const treeMode = getTreeMode(bucketRestriction);
	const isValidAttribute = await validAttribute(bucketRestriction);

	if (!isValidAttribute) {
		return 0;
	}

	const segment = {
		free_form_text_search: '',
		[`${treeMode}_restriction`]: {
			restriction: {
				bucketRestriction: {
					...bucketRestriction,
				},
			},
		},
	};

	if (segmentName) {
		segment.preexisting_segment_name = segmentName;
	}

	return getCountByQuery(`${treeMode}s`, segment);
};
// #endregion updateBucketCount

export {
	showType,
	showTo,
	setValuesBasedOnPosition,
	getEntity,
	getLastValueOrNull,
	getNullOrLastValue,
	getValuesBasedOnPosition,
	getOperationLabel,
	getBooleanValue,
	getStringValue,
	getOperationValue,
	isSameAttributeAndBucket,
	getAttributeRules,
	isBucketUsed,
	getBktValues,
	getValue,
	getValues,
	changeBooleanValue,
	changeValues,
	changeCmp,
	changeCmpValue,
	changeBktValue,
	changeActivityNumber,
	changeValue,
	changeTimeframePeriod,
	resetBktValues,
	removeKey,
	getBooleanModel,
	getCmp,
	getActivityNumber,
	getCubeBktList,
	getBucketCmp,
	getPeriodValue,
	getLastValue,
	showEmptyOption,
	hasInputs,
	changeNumericalCmpValue,
	updateBucketCount,
};

export type {
	GetValuesBasedOnPositionReturn,
	GetBktValuesReturn,
	GetValueParams,
	GetValuesParams,
	ChangeCmpValueParams,
	ChangeValueParams,
	ChangeTimeframePeriodParams,
	ResetBktValuesParams,
	RemoveKeyParams,
	GetBucketCmpParams,
	GetPeriodValueParams,
};
