import {AuthenticationConfig} from '../../Authentication/AuthenticationType';
import {ObjectType} from '../../ConnectionType';
import {SystemType} from '../../SystemType';

enum BrokerOwnerType {
	Stitch = 'Stitch',
	Tray = 'Tray',
	OpenPrise = 'OpenPrise',
}

enum BrokerInitiatorType {
	Lattice = 'Lattice',
}

interface IBrokerAuthInfo {
	authCode?: string;
	state?: string;
	session_state?: string;
	isSandbox?: boolean;
	refreshToken?: string;
	instanceUrl?: string;
	redirectUri?: string;
	clientId?: string;
	clientSecret?: string;
	endPoint?: string;
	subdomain?: string;
	accessToken?: string;
	signature?: string;
	issuedAt?: string;
	id?: string;
	pardotBusinessUnitId?: string;
	apiToken?: string;
	productName?: string;
}

interface IAuthInfoRequestBody extends ObjectType {
	systemType: SystemType;
	brokerAuthInfo: IBrokerAuthInfo;
	owner: BrokerOwnerType;
	initiator: BrokerInitiatorType;
}

interface IAuthInfoResponse extends ObjectType {
	created: number;
	updated: number;
	name: string;
	externalId: number | null;
	systemType: SystemType;
	brokerAuthInfo: IBrokerAuthInfo;
	owner: BrokerOwnerType;
	initiator: BrokerInitiatorType;
}

interface IBrokerAuthRequestBody extends ObjectType {
	systemType: SystemType;
	brokerAuthInfo: IBrokerAuthInfo;
	owner: BrokerOwnerType;
	initiator: BrokerInitiatorType;
}

interface IBrokerAuthResponse extends ObjectType {
	created: number;
	updated: number;
	name: string;
	externalId?: string;
	systemType: SystemType;
	brokerAuthInfo: IBrokerAuthInfo;
	owner: BrokerOwnerType;
	initiator: BrokerInitiatorType;
}

const BrokerAuthInfoUrl = '/pls/brokerAuth/auth-info';
const BrokerAuthUrl = '/pls/brokerAuth';
const BrokerWithTrayUrl = '/pls/brokerAuth/createWithTray';

const getAuthInfoRequestBody = ({
	systemType,
	code,
	state,
	session_state,
	isSandbox,
	isMetadataConnection,
}: {
	systemType: SystemType;
	code: string;
	state?: string;
	session_state?: string;
	isSandbox: boolean;
	isMetadataConnection: boolean;
}): IAuthInfoRequestBody => {
	const {
		redirectUri,
		owner = isMetadataConnection
			? BrokerOwnerType.Tray
			: BrokerOwnerType.Stitch,
	} = AuthenticationConfig[systemType]!;

	return {
		systemType,
		brokerAuthInfo: {
			authCode: code,
			state,
			session_state,
			isSandbox,
			redirectUri: redirectUri as string,
		},
		owner: owner as BrokerOwnerType,
		initiator: BrokerInitiatorType.Lattice,
	};
};

const getBrokerAuthRequestBody = (
	systemType: SystemType,
	isSandbox: boolean,
	refreshToken: string,
	instanceUrl: string,
	pardotBusinessUnitId?: string,
	id?: string
): IBrokerAuthRequestBody => {
	const {redirectUri, owner = BrokerOwnerType.Stitch} =
		AuthenticationConfig[systemType]!;
	return {
		systemType,
		brokerAuthInfo: {
			isSandbox,
			refreshToken,
			instanceUrl,
			redirectUri: redirectUri as string,
			pardotBusinessUnitId,
			id,
		},
		owner: owner as BrokerOwnerType,
		initiator: BrokerInitiatorType.Lattice,
	};
};

const getMarketoBrokerAuthRequestBody = (
	systemType: SystemType,
	clientId: string,
	clientSecret: string,
	endPoint: string
): IBrokerAuthRequestBody => {
	return {
		systemType,
		brokerAuthInfo: {
			clientId,
			clientSecret,
			endPoint,
		},
		owner: BrokerOwnerType.Stitch,
		initiator: BrokerInitiatorType.Lattice,
	};
};

const getGeneralStitchBrokerAuthRequestBody = <T>({
	systemType,
	...authParams
}: {systemType: SystemType} & T): IBrokerAuthRequestBody => {
	return {
		systemType,
		brokerAuthInfo: authParams,
		owner: BrokerOwnerType.Stitch,
		initiator: BrokerInitiatorType.Lattice,
	};
};

const getTrayBrokerAuth = (
	systemType: SystemType,
	brokerAuthInfo: IBrokerAuthInfo,
	name: string
): IBrokerAuthRequestBody => {
	return {
		systemType,
		name,
		owner: BrokerOwnerType.Tray,
		initiator: BrokerInitiatorType.Lattice,
		brokerAuthInfo,
	};
};

export {
	BrokerOwnerType,
	BrokerInitiatorType,
	BrokerAuthUrl,
	BrokerAuthInfoUrl,
	BrokerWithTrayUrl,
	getAuthInfoRequestBody,
	getBrokerAuthRequestBody,
	getMarketoBrokerAuthRequestBody,
	getTrayBrokerAuth,
	getGeneralStitchBrokerAuthRequestBody,
};

export type {
	IBrokerAuthInfo,
	IBrokerAuthRequestBody,
	IBrokerAuthResponse,
	IAuthInfoRequestBody,
	IAuthInfoResponse,
};
