import {isProductionHostname} from 'common/app/utilities/EnvUtility';
import {generateRandomString} from 'app/utilities/StringUtilities';
import {ObjectType} from '../ConnectionType';
import {ImportSubsystemType} from '../ImportSubsystemType';
import {SystemType} from '../SystemType';

type IAuthenticationConfig = {
	[x in SystemType]?: ObjectType;
};

interface IAuthParams {
	clientId: string;
	clientSecret: string;
	endPoint: string;
}

interface IAuthConfig {
	clientId?: string;
	clientSecret?: string;
	clientUrl?: string;
	redirectUri?: string;
	responseCodeUri?: (isSandbox?: boolean, authParams?: ObjectType) => string;
}

interface ISalesForceOAuthResponse {
	access_token?: string;
	id?: string;
	id_token?: string;
	instance_url?: string;
	issued_at?: string;
	refresh_token?: string;
	scope?: string;
	signature?: string;
	token_type?: string;
}

interface IImportAuthValue {
	systemId: string;
	isSandbox: boolean;
	originFrom: ImportSubsystemType;
}

const DefaultImportAuthValue: IImportAuthValue = {
	systemId: '',
	isSandbox: true,
	originFrom: ImportSubsystemType.Connection,
};

const ImportAuthKey = 'ImportAuthKey';

const isProd = isProductionHostname();

const SalesforceAuthentication = {
	clientId: isProd
		? '3MVG9CVKiXR7Ri5pPqwTCwH7W3qxBCCxH9DEzqTAC3JRwbH1K_FQMJJP8AQSOvpwfY5Tu3VTOikNAZ7HpY6l_'
		: '3MVG9szVa2RxsqBZMi1Z5IyMS6LpmslEkTF5Y3m2fIwjgpgG9aRbYPaUuSbT0L.oc1X41jOjsRA9DhCloTDrc',
	clientUrl: {
		Prod: 'https://login.salesforce.com',
		SandBox: 'https://test.salesforce.com',
	},
	redirectUri: `${window.location.origin}/atlas/callback`,
	responseCodeUri: (isSandbox: boolean): string => {
		const {clientUrl, clientId, redirectUri} = SalesforceAuthentication;
		const url = isSandbox ? clientUrl.SandBox : clientUrl.Prod;
		return `${url}/services/oauth2/authorize?response_type=code&client_id=${clientId}&redirect_uri=${redirectUri}&prompt=login`;
	},
};

const PardotAuthentication = {
	clientId: isProd
		? '3MVG9l2zHsylwlpSD12fmBuw8V6siBzNNcVUAAn.UrFz3lBq7D4g4fDMNTfpRahTA5kVTIaPkYnm0c0gdQ66O'
		: '3MVG9l2zHsylwlpSD12fmBuw8V6siBzNNcVUAAn.UrFz3lBq7D4g4fDMNTfpRahTA5kVTIaPkYnm0c0gdQ66O',
	clientUrl: 'https://login.salesforce.com',
	redirectUri: `${window.location.origin}/atlas/callback`,
	responseCodeUri: (): string => {
		const {clientUrl, clientId, redirectUri} = PardotAuthentication;
		return `${clientUrl}/services/oauth2/authorize?response_type=code&client_id=${clientId}&redirect_uri=${redirectUri}`;
	},
};

const EloquaAuthentication = {
	clientUrl: 'https://login.eloqua.com',
	clientId: isProd
		? '1bf67c4d-8c4e-4eec-974d-61bc5285f306'
		: 'c8bf8ec7-abfb-47c5-951c-8ad3ea43dade',
	clientSecret: '',
	redirectUri: `${window.location.origin}/atlas/callback`,
	responseCodeUri: (): string => {
		const {clientUrl, clientId, redirectUri} = EloquaAuthentication;
		return `${clientUrl}/auth/oauth2/authorize?response_type=code&client_id=${clientId}&redirect_uri=${redirectUri}`;
	},
};

const LinkedInAuthentication = {
	clientUrl: 'https://www.linkedin.com',
	clientId: isProd ? '86gksshoc03y4o' : '86gksshoc03y4o', // This is for testing at the moment, but it will be updated in the future
	redirectUri: `${window.location.origin}/atlas/callback`,
	responseCodeUri: (): string => {
		const {clientUrl, clientId, redirectUri} = LinkedInAuthentication;
		const state = btoa(generateRandomString()); // A unique string value of your choice that is hard to guess. Used to prevent CSRF.
		window.sessionStorage.setItem('OAuthState', state);

		return `${clientUrl}/oauth/v2/authorization?response_type=code&client_id=${clientId}&redirect_uri=${redirectUri}&state=${state}&scope=rw_ads`;
	},
};

const MicrosoftDynamicsAuthentication = {
	owner: 'OpenPrise',
	redirectUri: `${window.location.origin}/atlas/callback`,
	responseCodeUri: (
		_: unknown,
		{instance_url}: {instance_url: string}
	): string =>
		`https://login.microsoftonline.com/common/oauth2/authorize?response_type=code&client_id=3a622bfa-96e2-4666-81fe-c3cbbe002235&redirect_uri=${encodeURI(
			MicrosoftDynamicsAuthentication.redirectUri
		)}&state=%7B%22instance_url%22%3A%22${instance_url}%22%7D`,
};

const AuthenticationConfig: IAuthenticationConfig = {
	[SystemType.Salesforce]: SalesforceAuthentication,
	[SystemType.Eloqua]: EloquaAuthentication,
	[SystemType.Pardot]: PardotAuthentication,
	[SystemType.LinkedIn]: LinkedInAuthentication,
	[SystemType.Microsoft_Dynamics365]: MicrosoftDynamicsAuthentication,
};

export {
	AuthenticationConfig,
	SalesforceAuthentication,
	ImportAuthKey,
	DefaultImportAuthValue,
};
export type {
	IAuthConfig,
	ISalesForceOAuthResponse,
	IImportAuthValue,
	IAuthParams,
};
