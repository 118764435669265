import type {BucketRestriction, Bkt, Val, Vals} from '../../../query.types';
import {BucketCmp, TransactionKey, Direction} from '../../../query.enums';
import {CompareData, InputConfig, InputRangeConfig} from '../../../query.types';
import {
	compareMap,
	compareMapValues,
	directionMap,
	directionMapValues,
} from './percentStore.constants';
import type {
	DirectionData,
	Form,
	GetChangeValueParams,
} from './percentStore.types';
import {ChangeValueParams} from './percentStore.types';
import {getLastValue} from '../tree.helpers';

const changeCompare = (bucket: Bkt, compareType: BucketCmp): Bkt => {
	const modifiedBucket = {...bucket};

	if (modifiedBucket.Chg) {
		modifiedBucket.Chg.Cmp = compareType;
	}

	return modifiedBucket;
};

const changeValue = ({
	compareType,
	index,
	values,
	value,
}: ChangeValueParams): Vals => {
	const assignedValues = [...values];

	if (compareType === BucketCmp.BETWEEN) {
		assignedValues[index] = value;
		return assignedValues;
	}

	assignedValues[0] = value;
	return assignedValues;
};

const getNumericalPeriodConfig = (): InputRangeConfig => ({
	from: {
		name: 'from-period-percent',
		index: 0,
		type: TransactionKey.Avg,
		min: '0',
		max: '',
	},
	to: {
		name: 'to-period-percent',
		index: 1,
		type: TransactionKey.Avg,
		min: '0',
		max: '',
	},
});

const getCompareList = (): CompareData[] => {
	return [...compareMapValues];
};

const getCompareReadable = (bucketRestriction: BucketRestriction): string => {
	const cmp = bucketRestriction.bkt?.Chg?.Cmp ?? BucketCmp.EMPTY;
	const {displayName = ''} = compareMap.get(cmp) ?? {};

	return displayName;
};

const getConfigField = (configField: keyof InputRangeConfig): InputConfig => {
	const periodConfig = getNumericalPeriodConfig();
	return periodConfig[configField];
};

const getDirection = (bucket: Bkt): Direction => {
	const direction = bucket.Chg?.Direction;
	const modifiedBucket = {...bucket};

	return modifiedBucket.Chg && direction ? direction : Direction.DEC;
};

const setDirection = (bkt: Bkt, direction: Direction): void => {
	const {Chg = {Direction: Direction.DEC}} = bkt;
	Chg.Direction = direction;
};

const getDirectionList = (): DirectionData[] => {
	return [...directionMapValues];
};

const getDirectionReadable = (bucketRestriction: BucketRestriction): string => {
	const emptyDirectionData = {readable: ''};
	const direction = bucketRestriction.bkt?.Chg?.Direction;
	const directionData = direction
		? directionMap.get(direction)
		: emptyDirectionData;
	const {readable = ''} = directionData ?? emptyDirectionData;

	return readable;
};

const getErrorMsg = (
	isFormFieldVisible?: boolean,
	isToFieldVisible?: boolean
): string => {
	return isFormFieldVisible && isToFieldVisible
		? 'Enter a valid range'
		: 'Enter a valid number';
};

const getChangeValue = ({
	compareType,
	bucket,
	index,
}: GetChangeValueParams): Val | null => {
	const values = bucket.Chg?.Vals || [];

	if (compareType === BucketCmp.BETWEEN) {
		return values[index];
	}

	const {isLastOrEmpty, lastValue} = getLastValue({values, index});

	if (
		(compareType === BucketCmp.AS_MUCH_AS ||
			compareType === BucketCmp.AT_LEAST) &&
		isLastOrEmpty
	) {
		return lastValue;
	}

	return null;
};

const getValuesFormatted = (bucketRestriction: BucketRestriction): string => {
	const {bkt} = bucketRestriction;
	const values = bkt?.Chg?.Vals;

	return values ? values.toString().replace(/,/g, ' - ') : '';
};

const isPeriodRangeValid = (form: Form): boolean => {
	const isConfigFromValid = form['from-period-percent']?.$valid;
	const isConfigToValid = form['to-period-percent']?.$valid;

	const isValidRange = isConfigFromValid && isConfigToValid;

	return !!isValidRange;
};

const resetChangeValues = (bucket: Bkt): Bkt => {
	const modifiedBucket = {...bucket};

	if (modifiedBucket.Chg) {
		modifiedBucket.Chg.Vals = [];
	}

	return modifiedBucket;
};

export {
	changeCompare,
	changeValue,
	getCompareList,
	getCompareReadable,
	getConfigField,
	setDirection,
	getDirection,
	getDirectionList,
	getDirectionReadable,
	getErrorMsg,
	getNumericalPeriodConfig,
	getChangeValue,
	getValuesFormatted,
	isPeriodRangeValid,
	resetChangeValues,
};
