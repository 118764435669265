import React from 'common/react-vendor';
import {DNBTypography, DNBTextField} from 'common/dnb-uux-vendor';
import {ICell} from 'atlas/JourneyStage/JourneyStageConfiguration/Component/EditableCell';

/**
 * ISectionData interface.
 * @param title Title
 * @param cell data and setter
 */
type ISectionData = {
	title: string;
	cell: ICell<string>;
};

/**
 * ISectionConfig interface.
 * @param disabled True if disabled.
 * @param required Set when is used in a form.
 * @param multiline True if multiline.
 * @param rows Row number with multiline is enabled.
 * @param maxLength Input max length
 */
type ISectionConfig = {
	disabled?: boolean;
	required?: boolean;
	multiline?: boolean;
	rows?: number;
	maxLength?: number;
};

/**
 * ISectionComponent interface
 */
type ISectionComponent = ISectionData & ISectionConfig;

/**
 * SectionComponent
 * Titlte + Input with limit check.
 * @param prop @ISectionComponent
 */
const Section = ({
	title,
	cell: {data, setData},
	disabled,
	required,
	multiline,
	rows,
	maxLength,
}: ISectionComponent): React.ReactElement => {
	return (
		<>
			<DNBTypography variant='compact-medium'>{title}</DNBTypography>
			<DNBTextField
				required={required}
				fullWidth
				variant='outlined'
				size='small'
				multiline={multiline}
				rows={rows}
				inputProps={{maxLength}}
				disabled={!!disabled}
				value={data}
				onChange={(e) => setData(e.target.value)}
			/>
			{!!maxLength && (
				<DNBTypography variant='compact-body'>
					{maxLength - data.length < 0 ? 0 : maxLength - data.length} characters
					remaining
				</DNBTypography>
			)}
		</>
	);
};

const MAX_NAME_LENGTH = 50;
const MAX_DESCRIPTION_LENGTH = 255;

const DialogComponent = {
	Section,
};

const DialogLimit = {
	MAX_NAME_LENGTH,
	MAX_DESCRIPTION_LENGTH,
};

export {DialogComponent, DialogLimit};
