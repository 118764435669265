import React from 'common/react-vendor';
import {
	DNBDialog,
	DNBTypography,
	DNBSelect,
	DNBSelectOption,
} from 'common/dnb-uux-vendor';
import {Modules, hasModule} from 'common/stores/tenantConfig';
import RBAC, {
	AccessGroups,
} from 'common/app/utilities/RoleBasedAccessControl/RBAC';
import UserValues from 'common/widgets/utilities/user-values.utility';
import type {ISegment, ITeam} from 'atlas/data/SegmentConst';
import {truncate} from 'app/utilities/StringUtilities';
import {axiosInstance} from 'common/app/utilities/axiosUtility/axiosInstance';
import {
	deleteAngularHashKey,
	sanitizeSegment,
} from 'common/components/datacloud/segment/segment.helpers';
import {Query} from 'common/components/datacloud/datacloud.types';
import {actions as teamActions} from '../teams/teams.redux';
import {ISegmentEditDialog} from './segmentation.constants';
import {
	isAccountOrContactListSegmentType,
	refreshList,
} from './Helper/SegmentHelper';
import {
	DialogComponent,
	DialogLimit,
} from './Components/Dialog/DialogComponent';

const EditSegmentDialog = ({
	open,
	onOkay,
	onCancel,
	...segmentData
}: ISegmentEditDialog): React.ReactElement => {
	const {name, display_name = '', teamId = '', description = ''} = segmentData;
	const [isSubmitting, setIsSubmitting] = React.useState(false);
	const [segmentName, setSegmentName] = React.useState(display_name);
	const [segmentTeam, setSegmentTeam] = React.useState(teamId);
	const [teams, setTeams] = React.useState<ITeam[]>([]);
	const [segmentDescription, setSegmentDescription] =
		React.useState(description);
	const nameAndDescDisabled = !(
		segmentData.type === 'Query' ||
		(hasModule(Modules.LIST_SEGMENT) &&
			isAccountOrContactListSegmentType(segmentData))
	);
	const emailDisabled =
		!RBAC.userHasAccessLevel(AccessGroups.ADMINS) &&
		segmentData.created_by !== UserValues.getUserEmail();

	React.useEffect(() => {
		if (open) {
			teamActions.getTeams({
				session: true,
				callback: (response: ITeam[]) => {
					setTeams(response);
				},
			});
		}
	}, [open]);
	return (
		<DNBDialog
			title='Edit Segment'
			open={open}
			hasStickyFooter={false}
			hasCloseButton
			onClose={() => !isSubmitting && onCancel?.()}
			disabledSecondaryCTA={isSubmitting}
			secondaryCTA={onCancel}
			disabledPrimaryCTA={isSubmitting || !segmentName}
			primaryCTA={async () => {
				const data = {
					...deleteAngularHashKey(
						sanitizeSegment(segmentData as unknown as Query)
					),
					display_name: segmentName,
					teamId: segmentTeam,
					description: segmentDescription,
				};
				setIsSubmitting(true);
				try {
					const {status}: {status: number; data: ISegment} =
						await axiosInstance({
							url: '/pls/datacollection/segments',
							params: {isSimpleUpdate: true}, // True on update name team and description fields.
							method: 'post',
							data,
						});
					if (status === 200) {
						refreshList();
					}
					setIsSubmitting(false);
					onOkay?.(
						{
							id: name,
							name: segmentName,
							description: segmentDescription,
						},
						status === 200
					);
				} catch (e) {
					setIsSubmitting(false);
				}
			}}
			primaryCTALabel='Save'>
			{isSubmitting ? (
				<DNBTypography variant='body'>Saving in progress...</DNBTypography>
			) : (
				<div id='segmentationEditForm'>
					<div>
						<DialogComponent.Section
							title='Name'
							cell={{data: segmentName, setData: setSegmentName}}
							maxLength={DialogLimit.MAX_NAME_LENGTH}
							disabled={nameAndDescDisabled}
						/>
					</div>
					<div>
						<DNBTypography variant='compact-medium'>Team</DNBTypography>
						{teams.length ? (
							<DNBSelect<string>
								minWidth='505px'
								size='small'
								disabled={emailDisabled}
								value={segmentTeam}
								onChange={(_e, value) => setSegmentTeam(value || '')}>
								{teams.map(({TeamId, TeamName}) => (
									<DNBSelectOption value={TeamId} key={TeamId}>
										{truncate(TeamName, 22)}
									</DNBSelectOption>
								))}
							</DNBSelect>
						) : (
							<DNBTypography
								component='p'
								variant='compact-medium'
								sx={{lineHeight: '32px'}}>
								Loading...
							</DNBTypography>
						)}
					</div>
					<div id='segmentationEditDescription'>
						<DialogComponent.Section
							title='Description'
							cell={{
								data: segmentDescription,
								setData: setSegmentDescription,
							}}
							multiline
							maxLength={DialogLimit.MAX_DESCRIPTION_LENGTH}
							disabled={nameAndDescDisabled}
						/>
					</div>
				</div>
			)}
		</DNBDialog>
	);
};

export {EditSegmentDialog};
