import React from 'common/react-vendor';
import type {
	BucketRestriction,
	CompareData,
	InputRangeConfig,
	Val,
} from '../../../../query.types';
import {TreeType, BucketCmp} from '../../../../query.enums';
import {NumericalRange} from '../../edit/NumericalRange/NumericalRange';
import {changeValue} from '../../tree.helpers';
import {SubType} from '../../tree.types';

const {useState} = React;

interface SubTypeSelectionProps {
	type?: TreeType;
	bucketRestriction?: BucketRestriction;
	purchased: string;
	title: string;
	cmpList: CompareData[];
	initialCmp: BucketCmp | '';
	onCmpChange(newCmp: BucketCmp): void;
	onRangeChange(): void;
	showFrom: boolean;
	showTo: boolean;
	rangeConfig: InputRangeConfig;
	fromLabel?: string;
	toLabel?: string;
}

const SubTypeSelection = ({
	type,
	bucketRestriction,
	purchased,
	title,
	cmpList,
	initialCmp,
	onCmpChange,
	onRangeChange,
	showFrom,
	showTo,
	rangeConfig,
	fromLabel,
	toLabel,
}: SubTypeSelectionProps): React.ReactElement | null => {
	const [cmpValue, setCmpValue] = useState(initialCmp);

	if (purchased !== 'Yes') {
		return null;
	}

	const handleNumericalRangeChange = (
		index: number,
		subType?: string,
		value?: Val
	): void => {
		changeValue({
			bucketRestriction,
			type,
			value,
			index,
			subType: subType as SubType,
		});

		onRangeChange();
	};

	return (
		<div className='query-section-item-operation transaction-row'>
			<div className='transaction-element-title'>{title}</div>
			<div className='transaction-element'>
				<select
					value={cmpValue}
					onChange={({target: {value}}) => {
						const newCmp = value as BucketCmp;

						onCmpChange(newCmp);
						setCmpValue(newCmp);
					}}>
					{cmpList.map(({name, displayName}) => (
						<option key={name} value={name}>
							{displayName}
						</option>
					))}
				</select>
			</div>

			{(showFrom || showTo) && (
				<div className='transaction-element'>
					<NumericalRange
						config={rangeConfig}
						showMessage
						showFrom={showFrom}
						showTo={showTo}
						bucketRestriction={bucketRestriction}
						onChange={({type, index, value}) =>
							handleNumericalRangeChange(index, type, value)
						}
						fromLabel={fromLabel}
						toLabel={toLabel}
					/>
				</div>
			)}
		</div>
	);
};

export {SubTypeSelection};
