import {ECdpDataType} from 'atlas/connectors/EIF/schema/schemaTypes';
import {
	IRelationShipAttribute,
	IRelationShipGroupItem,
	IRelationShipItem,
} from 'atlas/connectors/EIF/RelationShipSetting/Components/RelationShipGroupList';
import {
	IDocumentTypeAttribute,
	IDocumentTypeAttributeGroup,
} from 'atlas/connectors/EIF/RelationShipSetting/Components/SystemDocumentTypeGroup';
import {ObjectType} from '../../ConnectionType';
import {
	ICdpAttributeMapping,
	ICdpMappingConfig,
	IGetSourcePerConnection,
	toImportSourceResponse,
} from './GetSourcePerConnection';
import {DocumentType} from '../../DocumentType';
import {SystemType} from '../../SystemType';
import {IGetSourcePerConnectionItem} from './GetSourceById';
import {IEntityRelationRequest, IInboundSourceRequest} from './InboundSource';
import {IImportSourceResponse} from '../FE/ImportSourceResponse';

const GetSourceIdListUrl = '/pls/entityrelation/getAvailableSourceIdList';
const GetTargetSourceIdListUrl = '/pls/entityrelation/getAvailableDestIdList';

interface IRelationShipQuery extends ObjectType {
	sourceUniqueId: string;
}

interface ICdpAttribute extends ObjectType {
	fieldName: string;
	fieldType: ECdpDataType;
}

interface IGetAttributeIdList extends ObjectType {
	Result: ICdpMappingConfig;
}

const toGetSourceIdList = ({
	Result: {cdpAttributeMappings},
}: IGetAttributeIdList): ICdpAttribute[] => {
	return cdpAttributeMappings
		.map(({displayName, cdpAttributeName}) => {
			return {
				fieldId: cdpAttributeName,
				fieldName: displayName,
				fieldType: ECdpDataType.Id,
			} as ICdpAttribute;
		})
		.flat();
};

interface IGetTargetSourceIdListQuery extends ObjectType {
	sourceSourceUniqueId: string;
	destSourceUniqueId: string;
}

const GetTargetSourceListUrl = '/pls/entityrelation/getAvailableDestSource';

const toGetTargetSourceList = (
	data: IGetSourcePerConnection
): IImportSourceResponse[] => {
	return toImportSourceResponse(data);
};

/**
 * Relationship modal dialog data used by react state.
 * @param sourceConnectionType Source connection type Refers to @SystemType
 * @param sourceConnectionName Source connection name
 * @param sourceUniqueId Source unique id
 * @param sourceDisplayName Source display name
 * @param sourceMatchColumn Source match id
 * @param sourceMatchDisplayName Source match display name
 * @param destConnectionType Destination connection type
 * @param destConnectionName Destination connection name
 * @param destMatchColumn Destination match id
 * @param destMatchDisplayName Destination match display name
 * @param destUniqueId Destination unique id
 */
interface IRelationShipNewItem extends ObjectType {
	sourceConnectionType: SystemType;
	sourceConnectionName: string;
	sourceUniqueId: string;
	sourceDisplayName: string;
	sourceMatchColumn: string;
	sourceMatchDisplayName: string;
	destConnectionType: SystemType;
	destConnectionName: string;
	destMatchColumn: string;
	destMatchDisplayName: string;
	destUniqueId: string;
	destDisplayName: string;
	schemaType: string;
	enabled?: boolean;
}

interface ICreateRelationShipRequestBody extends ObjectType {
	currentEntityRelation: IRelationShipNewItem;
	inboundSourceRequest: IInboundSourceRequest;
	entityRelationRequest: IEntityRelationRequest;
}

const DefaultRelationShipNewItem: IRelationShipNewItem = {
	sourceConnectionType: SystemType.Salesforce,
	sourceConnectionName: '',
	sourceUniqueId: '',
	sourceDisplayName: '',
	sourceMatchColumn: '',
	sourceMatchDisplayName: '',
	destConnectionType: SystemType.Salesforce,
	destConnectionName: '',
	destMatchColumn: '',
	destMatchDisplayName: '',
	destUniqueId: '',
	destDisplayName: '',
	schemaType: DocumentType.Account,
	enabled: true,
};

const DefaultEntityRelationRequest = {
	entityRelationRequestItemList: [],
};

const updateRelationShipListUrl = (sourceId: string): string => {
	return `/pls/entityrelation/sourceEntityRelation/${sourceId}`;
};

interface IGetRelationShipListResponse extends ObjectType {
	Result: {
		sourceRelations: IGetRelationShipList[];
	};
	Success?: boolean;
}

interface IGetRelationShipList extends ObjectType {
	destSource: IGetSourcePerConnectionItem[];
	enabled: boolean;
	enrichIds: ICdpAttributeMapping[];
	enrichPriority: number;
	matchId: ICdpAttributeMapping;
	sourceSource: IGetSourcePerConnectionItem;
}

const toRelationshipGroupItemRow = ({
	sourceConnectionType,
	sourceConnectionName,
	sourceDisplayName,
	sourceUniqueId,
	sourceMatchColumn,
	sourceMatchDisplayName,
	destConnectionType,
	destConnectionName,
	destMatchColumn,
	destMatchDisplayName,
	destUniqueId,
	destDisplayName,
	schemaType,
}: IRelationShipNewItem): {
	fromItem: IRelationShipItem;
	toItem: IRelationShipItem;
} => {
	return {
		fromItem: {
			systemType: sourceConnectionType,
			connectionName: sourceConnectionName,
			sourceId: sourceUniqueId,
			sourceName: sourceDisplayName,
			attributeId: sourceMatchColumn,
			attributeName: sourceMatchDisplayName,
			matchAttributeId: destMatchColumn,
			matchAttributeName: destMatchDisplayName,
		},
		toItem: {
			systemType: destConnectionType,
			connectionName: destConnectionName,
			sourceId: destUniqueId,
			sourceName: destDisplayName,
			attributeId: destMatchColumn,
			attributeName: destMatchDisplayName,
			schemaType,
		},
	};
};

const toGetRelationShipListFEResponse = ({
	Result: {sourceRelations},
}: IGetRelationShipListResponse): IRelationShipGroupItem[] => {
	return sourceRelations
		.filter(({matchId, enrichIds}) => matchId || !!enrichIds?.length)
		.map(
			({
				enabled,
				sourceSource: {
					connectionType,
					dataFeedTaskUniqueId: sourceId,
					displayName: sourceName,
					connectionDisplayName,
					sourceCdpAttributeName,
					sourceCdpAttributeDisplayName,
				},
				destSource,
				matchId,
				enrichIds,
			}) => {
				const {
					cdpAttributeName: matchAttributeId,
					displayName: matchAttributeName,
				} = matchId || enrichIds[0];
				return {
					enabled,
					item: {
						systemType: connectionType || SystemType.Salesforce,
						connectionName: connectionDisplayName,
						sourceId,
						sourceName,
						attributeId: sourceCdpAttributeName,
						attributeName: sourceCdpAttributeDisplayName,
						matchAttributeId,
						matchAttributeName,
					},
					itemGroup: destSource.map(
						({
							connectionType,
							connectionDisplayName,
							dataFeedTaskUniqueId: sourceId,
							displayName: sourceName,
							sourceCdpAttributeDisplayName,
							sourceCdpAttributeName,
							schemaType,
						}) => ({
							systemType: connectionType || SystemType.Salesforce,
							connectionName: connectionDisplayName,
							sourceId,
							sourceName,
							attributeId: sourceCdpAttributeName,
							attributeName: sourceCdpAttributeDisplayName,
							schemaType,
						})
					),
				};
			}
		);
};

interface IGetRelationShipListRequestBody extends ObjectType {
	sourceRelations: IGetRelationShipList[];
}

const toUpdateRelationShipListRequestBody = (
	attributeEnableList: IRelationShipAttribute[]
): IGetRelationShipListRequestBody => {
	const attributeList = (
		(attributeEnableList as IRelationShipGroupItem[]) || []
	).map(({item: {matchAttributeId}, itemGroup, enabled}) => {
		return {
			enabled,
			matchId: {
				cdpAttributeName: matchAttributeId,
			},
			destSource: [{dataFeedTaskUniqueId: itemGroup?.[0]?.sourceId}],
		} as IGetRelationShipList;
	});
	return {
		sourceRelations: attributeList,
	} as IGetRelationShipListRequestBody;
};

interface IMatchColumn {
	column_name: string;
	display_name: string;
	enabled: boolean;
}

interface IGetSourceMatchConfigList extends ObjectType {
	account_match_columns?: IMatchColumn[];
	contact_match_columns?: IMatchColumn[];
}

interface IGetSourceMatchConfigResponse extends ObjectType {
	Errors?: string[];
	Result: IGetSourceMatchConfigList;
	Success?: boolean;
}

type IUpdateSourceMatchConfigRequestBody = IGetSourceMatchConfigList;

const toMatchConfigFEResponse = ({
	column_name: id,
	display_name: title,
	enabled: checked,
}: IMatchColumn): IDocumentTypeAttribute => {
	return {
		id,
		title,
		checked,
	};
};

const toGetSourceMatchConfigFEResponse = ({
	Result: {account_match_columns, contact_match_columns},
}: IGetSourceMatchConfigResponse): IDocumentTypeAttributeGroup[] => {
	const result = [];
	if (account_match_columns) {
		result.push({
			documentType: DocumentType.Account,
			dataList: account_match_columns.map((data) =>
				toMatchConfigFEResponse(data)
			),
		});
	}
	if (contact_match_columns) {
		result.push({
			documentType: DocumentType.Contact,
			dataList: contact_match_columns.map((data) =>
				toMatchConfigFEResponse(data)
			),
		});
	}
	return result;
};

const toGetSourceMatchConfigBEResponse = (
	attributeGroupList: IDocumentTypeAttributeGroup[]
): IGetSourceMatchConfigList => {
	return attributeGroupList.reduce(
		(pre: IGetSourceMatchConfigList, {documentType, dataList}) => {
			if (documentType === DocumentType.Account) {
				// eslint-disable-next-line no-param-reassign
				pre.account_match_columns = dataList.map(
					({id: column_name, title: display_name, checked: enabled}) => {
						return {
							column_name,
							display_name,
							enabled,
						};
					}
				);
			}
			if (documentType === DocumentType.Contact) {
				// eslint-disable-next-line no-param-reassign
				pre.contact_match_columns = dataList.map(
					({id: column_name, title: display_name, checked: enabled}) => {
						return {
							column_name,
							display_name,
							enabled,
						};
					}
				);
			}
			return pre;
		},
		{}
	);
};

export type {
	IRelationShipQuery,
	ICdpAttribute,
	IGetAttributeIdList,
	IGetTargetSourceIdListQuery,
	ICreateRelationShipRequestBody,
	IGetRelationShipListResponse,
	IGetRelationShipList,
	IUpdateSourceMatchConfigRequestBody,
	IGetSourceMatchConfigResponse,
	IGetSourceMatchConfigList,
	IRelationShipNewItem,
};
export {
	GetSourceIdListUrl,
	GetTargetSourceIdListUrl,
	toGetSourceIdList,
	GetTargetSourceListUrl,
	toGetTargetSourceList,
	DefaultRelationShipNewItem,
	DefaultEntityRelationRequest,
	updateRelationShipListUrl,
	toGetRelationShipListFEResponse,
	toUpdateRelationShipListRequestBody,
	toGetSourceMatchConfigFEResponse,
	toGetSourceMatchConfigBEResponse,
	toRelationshipGroupItemRow,
};
