enum QueryActionType {
	CLEAR_QUERY_STORE = 'CLEAR_QUERY_STORE',
	SET_QUERY_PROPERTY_VALUE = 'SET_QUERY_PROPERTY_VALUE',
	SET_QUERY_PUBLIC_VALUE = 'SET_QUERY_PUBLIC_VALUE',
	SET_ADD_BUCKET_TREE_ROOT = 'SET_ADD_BUCKET_TREE_ROOT',
	SET_COUNT_LOADING = 'SET_COUNT_LOADING',
}

enum Modes {
	Segmentation = 'segment',
	RulesBasedModelDetails = 'rules',
	RulesBasedModelDashboard = 'dashboardrules',
}

export {QueryActionType, Modes};
