import {AxiosPromise} from 'common/node_modules/axios';
import {ApiMethod} from 'atlas/connectors/EIF/Data/API/ApiType';
import {genericFetch} from 'atlas/connectors/EIF/Util/FetchWrapper';
import {ISegment} from 'atlas/data/SegmentConst';
import {ObjectType} from 'atlas/connectors/EIF/Data/ConnectionType';
import {axiosInstance} from 'common/app/utilities/axiosUtility/axiosInstance';
import {
	IJourneyStageData,
	IJourneyStageMapData,
} from 'atlas/JourneyStage/Data/JourneyStageData';
import {
	cacheQueryResponse,
	clearCacheStorage,
} from 'common/app/utilities/cacheUtility/cacheUtility';
import {AttributeStats} from 'common/components/datacloud/query/advanced/tree/types';
import {Campaign} from 'atlas/playbook/content/campaignstable/CampaignsTable';
import {Restriction} from 'common/components/datacloud/query/query.types';
import {
	DisplayType,
	IJourneyStageOverview,
	IPercentageData,
	ISegmentStatsResponse,
} from './Data/SegmentJourneyType';
import {INameDescriptionData} from '../Components/NameDescriptionHeader';

/**
 * API for switch journey stage map per segment.
 * @param segmentName Segment name
 * @param buyerJourneyMapId Journey stage map id.
 * @returns True on success.
 */
const switchJourneyStageMap = async (
	segmentName: string,
	buyerJourneyMapId: number
): Promise<boolean> => {
	clearCacheStorage();
	const {status} = await genericFetch({
		apiConfig: {
			url: () => `/pls/buyerJourney/switchBuyerJourneyMap/${segmentName}`,
			method: ApiMethod.post,
		},
		query: {buyerJourneyMapId},
	});
	return status === 200;
};

const getSegmentStatsDataUrl = ({name}: ObjectType): string =>
	`/pls/datacollection/segments/stats/${name}`;

/**
 * Get segment stats data all in one API.
 * @param segmentName Segment name as path param.
 * @returns Refers to @ISegmentStatsResponse
 * NOTE: Will be removed totally after BE supports all attribute
 * real-time query.
 */
const getAllSegmentStatsData = async (
	segmentName: string
): Promise<ISegmentStatsResponse> =>
	cacheQueryResponse(`getSegmentStatsData|${segmentName}`, () =>
		axiosInstance
			.get(getSegmentStatsDataUrl({name: segmentName}))
			.then((res) => res.data)
	);

/**
 * Get data status cubes from segment provided.
 * @param name The segment name.
 * @param version Version.
 * @return The data status cubes.
 */
const getSegmentData = (
	name: string,
	version: string
): AxiosPromise<boolean> => {
	return genericFetch({
		apiConfig: {
			url: () => getSegmentStatsDataUrl({name}),
			method: ApiMethod.post,
		},
		query: {version},
	}) as AxiosPromise<boolean>;
};

/**
 * Get segment per name url.
 */
const getSegmentUrl = ({name}: ObjectType): string =>
	`/pls/datacollection/segments/${name}`;

/**
 * Get journey stage overview.
 * @param segmentName Segment name
 * @param stageIdForSubStages Sub stages to query if provided.
 */
const getJourneyStageOverview = (
	segmentName: string,
	stageIdForSubStages?: number
): Promise<IJourneyStageOverview[]> =>
	cacheQueryResponse(
		`getJourneyStageOverview|${segmentName}`,
		() =>
			axiosInstance
				.get<IJourneyStageOverview[]>(`/pls/buyerJourney/query/allStages`, {
					params: {segmentName, stageIdForSubStages},
				})
				.then((res) =>
					res.data.map((data) => ({
						...data,
						totalAccount: data.totalAccount || 0,
						totalContact: data.totalContact || 0,
					}))
				),
		{refetch: true}
	);

/**
 * Get journey map data by segment.
 */
const getJourneyMapDataBySegment = (
	segmentName: string
): Promise<IJourneyStageMapData> =>
	cacheQueryResponse(
		`getJourneyMapDataBySegment|${segmentName}`,
		() =>
			axiosInstance
				.get(`/pls/buyerJourney/buyerJourneyMapBySegment/${segmentName}`)
				.then((res) => res.data),
		{refetch: true}
	);

/**
 * Get custom subsegments per parent segment.
 * @param name Parent segment name.
 * @returns The custom subsegments list
 * @remark Newly created API, the same as @getSubsegmentsUrl
 * with customParentSegmentName is presented.
 */
const getCustomSubsegmentsUrl = ({name}: ObjectType): string =>
	`/pls/datacollection/segments/${name}/customSubsegments`;

/**
 * Get subsegments per parent segment.
 * @param name Parent segment name.
 * @returns The subsegments list.
 */
const getSubsegmentsUrl = ({name}: ObjectType): string =>
	`/pls/datacollection/segments/${name}/subsegments`;

/**
 * Delete segment api.
 * @param name Segment name to delete
 * @returns True on success.
 */
const deleteSegment = async (name: string): Promise<boolean> => {
	const {status} = await genericFetch({
		apiConfig: {
			url: () => `/pls/datacollection/segments/${name}/modelAndView`,
			method: ApiMethod.delete,
		},
	});
	return status === 200;
};

/**
 * Duplicate segment api.
 * @param segment Segment payload.
 * @returns True on success.
 */
const duplicateSegment = async (segment: ISegment): AxiosPromise<ISegment> => {
	return genericFetch<ISegment>({
		apiConfig: {
			url: () => '/pls/datacollection/segments/clone',
			method: ApiMethod.post,
		},
		data: {
			name: segment.name,
			display_name: `${segment.display_name} (Copy)`,
		} as ISegment,
	}) as AxiosPromise<ISegment>;
};

type IAttributeType = 'SINGLE' | 'GROUP';

/**
 * Create or update card param interface.
 * @param pid Card id, undefined in create mode
 * @param name Attribute name
 * @param attributeEntity @BusinessEntity
 * @param displayName Attribute display name
 * @param category Category name
 * @param segmentName Segment name
 * @param description Attribute description
 * @param displayType @DisplayType
 * @param attributeType Single or Group
 * @param sort Card position
 * @param isGlobal Flag indicates if card is global or per segment.
 * @param isEditable True if editable
 * @param Stats Attribute name and @AttributeStats key value pair
 * @param data FE data format, coverted from field @Stats, FE only
 * @param onChange Callback on change, FE only
 */
interface ICreateOrUpdateCardParam extends ObjectType {
	pid?: number;
	name: string;
	attributeNames: string[];
	attributeEntity: string;
	displayName?: string;
	segmentName: string;
	description?: string;
	displayType: DisplayType;
	attributeType: IAttributeType;
	sort: number;
	isGlobal?: boolean;
	isEditable?: boolean;
	Stats?: Record<string, AttributeStats>;
	data?: Record<string, IPercentageData>[];
	onChange?: (card: ICreateOrUpdateCardParam) => void;
	onError?: (card: ICreateOrUpdateCardParam) => void;
}

/**
 * Create or update card attribute per segment api.
 * @returns True on success.
 */
const createOrUpdateCard = (
	data: ICreateOrUpdateCardParam
): AxiosPromise<ICreateOrUpdateCardParam> => {
	return genericFetch<ICreateOrUpdateCardParam>({
		apiConfig: {
			url: () => `/pls/buyerJourney/cardInfo?segmentName=${data.segmentName}`,
			method: ApiMethod.post,
		},
		data,
	}) as AxiosPromise<ICreateOrUpdateCardParam>;
};

/**
 * Get card list url.
 * @param segment Segment name.
 * @return The card list url.
 */
const getCardListUrl = ({segment}: ObjectType): string =>
	`/pls/buyerJourney/cardsInfo?segmentName=${segment}`;

/**
 * Get card info url.
 * @param pid The card id.
 * @param segmentName Segment name
 * @returns The card info url
 */
const getCardInfoUrl = (segmentName: string, pid: number): string =>
	`/pls/buyerJourney/card?cardInfoId=${pid}&segmentName=${segmentName}`;

/**
 * Get segment stats data per attribute.
 * @param pid Card id.
 * @returns Refers to @ICreateOrUpdateCardParam
 */
const getCardInfo = async (
	segmentName: string,
	pid: number
): Promise<ICreateOrUpdateCardParam> =>
	axiosInstance.get(getCardInfoUrl(segmentName, pid)).then(({data}) => data);

const getPlaysByJourneyStageURL = (): string =>
	'/pls/play/filterByJourneyStages';

/**
 * Get plays by journey stage.
 * @param segmentName Segment name
 * @param stageMapId Stage map id
 * @param stageIds Stage id list.
 * @remarks
 * Actually 2 ways to use this api.
 * 1. provide @segmentName and @stageMapId
 * 2. provide @stageIds
 */
type IGetPlaysByJourney = {
	segmentName?: string;
	stageMapId?: number;
	stageIds?: number[];
};

type IPlayByJourney = Campaign;

/**
 * Get plays per journey stage config.
 * @param data @IGetPlaysByJourney
 * @returns List of @IPlayByJourney
 */
const getPlaysByJourneyStage = (
	data: IGetPlaysByJourney
): AxiosPromise<IPlayByJourney[]> =>
	axiosInstance.post(getPlaysByJourneyStageURL(), data);

type ISubJourneyStage = INameDescriptionData & {
	id: number;
	buyerJourneyStage?: IJourneyStageData; // Response
	buyerJourneyStageId?: number; // POST
	segmentName?: string; // POST
	segment?: ISegment; // Response
	accountRestriction?: string;
	accountRestrictionInstance?: Restriction;
	contactRestriction?: string;
	contactRestrictionInstance?: Restriction;
	pid: number;
};

const getAllStagesUrl = ({
	segmentName,
	stageIdForSubStages,
}: ObjectType): string =>
	`/pls/buyerJourney/query/allStages?segmentName=${segmentName}&stageIdForSubStages=${stageIdForSubStages}`;

const getSubJourneyStagesUrl = ({stageId, segmentName}: ObjectType): string =>
	`/pls/buyerJourney/buyerJourneySubStages?buyerJourneyStageId=${stageId}&segmentName=${segmentName}`;

const getSubJourneyStageUrl = (stageId: number): string =>
	`/pls/buyerJourney/buyerJourneySubStage/${stageId}`;

const getSubJourneyStage = (
	buyerJourneySubStageId: number
): AxiosPromise<ISubJourneyStage> =>
	axiosInstance.get(getSubJourneyStageUrl(buyerJourneySubStageId));

const createOrUpdateSubJourneyStage = (
	buyerJourneySubStage: ISubJourneyStage
): AxiosPromise<ISubJourneyStage> =>
	axiosInstance.post(
		'/pls/buyerJourney/buyerJourneySubStage/',
		buyerJourneySubStage
	);

const deleteSubJourneyStage = (
	buyerJourneySubStageId: number
): Promise<boolean> =>
	axiosInstance
		.delete(`/pls/buyerJourney/buyerJourneySubStage/${buyerJourneySubStageId}`)
		.then(({data}) => data?.Success);

/**
 * Segment related API.
 */
const API = {
	switchJourneyStageMap,
	getSegmentData,
	deleteSegment,
	duplicateSegment,
	createOrUpdateCard,
	getCardInfo,
	getPlaysByJourneyStage,
	getSubJourneyStage,
	createOrUpdateSubJourneyStage,
	deleteSubJourneyStage,
};

export type {
	IAttributeType,
	ICreateOrUpdateCardParam,
	IPlayByJourney,
	IGetPlaysByJourney,
	ISubJourneyStage,
};

export {
	API,
	getSegmentUrl,
	getSubsegmentsUrl,
	getCustomSubsegmentsUrl,
	getSegmentStatsDataUrl,
	getAllSegmentStatsData,
	getJourneyStageOverview,
	getJourneyMapDataBySegment,
	getCardListUrl,
	getPlaysByJourneyStageURL,
	getAllStagesUrl,
	getSubJourneyStagesUrl,
	getSubJourneyStageUrl,
};
