import {store} from 'store';
import type {
	LogicalRestrictionRecord,
	Restriction,
	Restrictions,
} from 'common/components/datacloud/query/query.types';
import {getCollectionStatus as getCollectionStatusQuery} from 'common/components/datacloud/query/queryService.queries';
import type {DataCollectionStatusResponse} from 'common/components/datacloud/query/queryService.queries';
import {getSegments} from 'common/components/datacloud/segment/segment.queries';
import {cacheQueryResponse} from 'common/app/utilities/cacheUtility/cacheUtility';
import type {
	QueryState,
	QueryPublic,
	QueryCount,
	QueryCounts,
	RestrictionType,
	QueryJourneyCounts,
} from './types';
import {
	clearQueryStore,
	setQueryPropertyValue,
	setQueryPublicValue,
	setAddBucketTreeRoot,
	setCountLoading,
} from './actions';
import {stateModeMap, defaultMode} from './constants';
import {Modes} from './enums';

const getQueryStore = (): QueryState => store.getState().query;

const getQueryProperty = <QueryProperty extends unknown>(
	key: keyof QueryState
): QueryProperty => getQueryStore()[key] as QueryProperty;

// #region flags
const isSegmentationMode = (): boolean =>
	getQueryProperty<Modes>('mode') === Modes.Segmentation;

const isRulesBasedModelMode = (): boolean =>
	[Modes.RulesBasedModelDashboard, Modes.RulesBasedModelDetails].includes(
		getQueryProperty<Modes>('mode')
	);
// #endregion flags

// #region getters
const getPublicProperty = (key: keyof QueryPublic): number | boolean | null => {
	const queryPublic = getQueryProperty<QueryPublic>('public');

	return queryPublic.hasOwnProperty(key) ? queryPublic[key] : null;
};
// #endregion getters

// #region fetch and set
const getCollectionStatus = async (): Promise<DataCollectionStatusResponse> => {
	const collectionStatus =
		getQueryProperty<DataCollectionStatusResponse | null>('collectionStatus');

	if (collectionStatus !== null) {
		return collectionStatus;
	}

	return getCollectionStatusQuery().then((response) => {
		store.dispatch(
			setQueryPropertyValue<DataCollectionStatusResponse>(
				'collectionStatus',
				response
			)
		);

		return response;
	});
};
// #endregion fetch and set

// #region setters
const dispatchClearQueryStore = (): void => {
	store.dispatch(clearQueryStore());
};

const dispatchQueryPropertyValue = <QueryValue>(
	key: keyof QueryState,
	value: QueryValue
): void => {
	store.dispatch(setQueryPropertyValue<QueryValue>(key, value));
};

const setModeFromState = (state: string): void => {
	dispatchQueryPropertyValue<Modes>('mode', stateModeMap[state] ?? defaultMode);
};

const setPublicProperty = (
	key: keyof QueryPublic,
	value: boolean | number
): void => {
	store.dispatch(setQueryPublicValue(key, value));
};

// #region setRestrictions
interface SetTypeRestrictionsParams {
	type: RestrictionType;
	restriction?: Restriction;
	restrictions?: Restrictions;
}

const setTypeRestriction = ({
	type,
	restriction,
	restrictions,
}: SetTypeRestrictionsParams): void => {
	if (restriction) {
		store.dispatch(setQueryPropertyValue<Restriction>(type, {...restriction}));
	}

	if (restrictions) {
		const currentRestriction = getQueryProperty<Restriction>(type);

		store.dispatch(
			setQueryPropertyValue<Restriction>(type, {
				...currentRestriction,
				restriction: {
					...currentRestriction.restriction,
				},
			})
		);
	}
};
interface SetRestrictionsParams {
	type: string;
	restriction?: Restriction;
	restrictions?: Restrictions;
}

const setRestrictions = ({
	type,
	restriction,
	restrictions,
}: SetRestrictionsParams): void => {
	switch (type) {
		case 'account':
			setTypeRestriction({
				type: 'accountRestriction',
				restriction,
				restrictions,
			});
			break;
		case 'member':
			setTypeRestriction({
				type: 'memberRestriction',
				restriction,
				restrictions,
			});
			break;
		default:
			setTypeRestriction({
				type: 'contactRestriction',
				restriction,
				restrictions,
			});
			break;
	}
};
// #endregion setRestrictions

const dispatchSetAddBucketTreeRoot = (
	tree: LogicalRestrictionRecord | null,
	type?: string
): void => {
	store.dispatch(setAddBucketTreeRoot(tree, type));
};

const setEntitiesProperty = (
	property: keyof QueryCount,
	value: boolean | number
): void => {
	const counts = {...getQueryProperty<QueryCounts>('counts')};

	for (const key in counts) {
		if (counts.hasOwnProperty(key)) {
			counts[key as keyof QueryCounts][property] = value as never;
		}
	}

	store.dispatch(setQueryPropertyValue<QueryCounts>('counts', {...counts}));
};

const setResourceTypeCount = (
	resourceType: keyof QueryCounts,
	loading?: boolean,
	value?: number
): void => {
	const counts = {...getQueryProperty<QueryCounts>('counts')};

	const resourceTypeCount = counts[resourceType];

	if (resourceTypeCount) {
		if (typeof value !== 'undefined') {
			resourceTypeCount.value = value;
		}

		if (typeof loading !== 'undefined') {
			resourceTypeCount.loading = loading;
		}
	}

	store.dispatch(setQueryPropertyValue<QueryCounts>('counts', {...counts}));
};

const setJourneyEntitiesProperty = (value?: QueryJourneyCounts): void => {
	store.dispatch(setQueryPropertyValue('journeyCounts', value));
};

const dispatchSetCountLoading = (
	key: keyof QueryCounts,
	value: boolean
): void => {
	store.dispatch(setCountLoading(key, value));
};
// #endregion setters

const setSegments = async (): Promise<void> =>
	cacheQueryResponse('getSegments', () => getSegments(), {
		storage: 'sessionStorage',
	})
		.then((result) => {
			const hasSegments = result.length > 0;

			if (hasSegments) {
				dispatchQueryPropertyValue('segments', result);
			}
		})
		.catch((error) => console.error(error));

export {
	getQueryStore,
	getQueryProperty,
	isSegmentationMode,
	isRulesBasedModelMode,
	getPublicProperty,
	getCollectionStatus,
	dispatchClearQueryStore,
	dispatchQueryPropertyValue,
	setModeFromState,
	setPublicProperty,
	setRestrictions,
	dispatchSetAddBucketTreeRoot,
	setEntitiesProperty,
	setJourneyEntitiesProperty,
	setResourceTypeCount,
	dispatchSetCountLoading,
	setSegments,
};
