import {store} from 'store';
import React, {react2angular, NgStateService} from 'common/react-vendor';
import {Provider} from 'common/redux-vendor';
import {IJourneyStageData} from 'atlas/JourneyStage/Data/JourneyStageData';
import {ISubJourneyStage} from 'atlas/segmentation/SegmentDashboard/ApiCalls';
import {QueryButtonGroup} from './QueryButtonGroup';
import {SubstageButtonGroup} from './SubstageButtonGroup';

type IQueryButtonGroup = {
	$state: NgStateService;
};

const QueryButtonGroupWithRedux = (prop: IQueryButtonGroup): JSX.Element => {
	return (
		<Provider store={store}>
			<QueryButtonGroup {...prop} />
		</Provider>
	);
};

type ISubstageButtonGroup = IQueryButtonGroup & {
	stage: IJourneyStageData;
	subStage?: ISubJourneyStage;
};

const SubstageButtonGroupWithRedux = (
	prop: ISubstageButtonGroup
): JSX.Element => {
	return (
		<Provider store={store}>
			<SubstageButtonGroup {...prop} />
		</Provider>
	);
};

angular
	.module('common.datacloud.query.builder.queryButtonGroup', [])
	.component(
		'queryButtonGroup',
		react2angular(QueryButtonGroupWithRedux, [], ['$state'])
	)
	.component(
		'substageButtonGroup',
		react2angular(
			SubstageButtonGroupWithRedux,
			['stage', 'subStage'],
			['$state']
		)
	);

export type {IQueryButtonGroup, ISubstageButtonGroup};
