import React from 'common/react-vendor';
import {
	ApartmentOutlinedIcon,
	CheckIcon,
	ContactsIcon,
	DNBIconButton,
	DNBTag,
	DNBTypography,
	DeleteOutlineIcon,
	JoinInnerIcon,
} from 'common/dnb-uux-vendor';
import {useSelector} from 'react-redux';
import {QueryState} from 'common/stores/query/types';
import classNames from 'classnames';
import {flattenLogicalRestrictions} from 'common/components/datacloud/segment/segment.helpers';
import {isRulesBasedModelMode} from 'common/stores/query';
import SvgUnion from '../../../../../../atlas/assets/svgrs/segmentation/Union';
import {IQueryBlock} from './queryBlockTypes';
import {Restriction} from '../../query.types';

const QueryBlock = (props: IQueryBlock): React.ReactElement => {
	const {scope, type} = props;
	const refresh = useSelector(
		(state: {query: QueryState}) => state?.query?.public.refresh
	);
	const isRules = isRulesBasedModelMode();
	let trees: Restriction[] = [];
	let title = 'Query';
	let icon = <ApartmentOutlinedIcon />;
	switch (type) {
		case 'Account':
			trees = isRules ? scope.accountRulesTree : scope.getAccountTree();
			title = `${type} Query`;
			icon = <ApartmentOutlinedIcon />;
			break;
		case 'Contact':
			trees = isRules ? scope.contactRulesTree : scope.getContactTree();
			title = `${type} Query`;
			icon = <ContactsIcon />;
			break;
		case 'Union':
			trees = scope.getSegmentUnionTree();
			title = 'Union Segment';
			icon = <SvgUnion />;
			break;
		case 'Intersection':
			trees = scope.getSegmentIntersectionTree();
			title = 'Intersection Segment';
			icon = <JoinInnerIcon />;
			break;
	}
	const restrictions = flattenLogicalRestrictions(
		trees[0]?.logicalRestriction?.restrictions || [],
		true
	);
	const label = `${restrictions.length} ${
		restrictions?.length > 1 ? 'attributes' : 'attribute'
	} selected`;
	return (
		<span className='query-section-icon'>
			<DNBIconButton size='default'>{icon}</DNBIconButton>
			<strong>{title}</strong>
			<DNBTag
				type={restrictions?.length ? 'type-2' : 'default'}
				key={type}
				sx={{
					m: 0,
				}}
				disabled={!restrictions?.length}
				label={
					<DNBTypography
						component='div'
						variant='caption'
						sx={{display: 'flex', alignItems: 'center'}}>
						{label}
					</DNBTypography>
				}
				leadingIcon={restrictions?.length ? <CheckIcon /> : undefined}
			/>
			{!!restrictions?.length && (
				<DNBIconButton
					className={classNames({
						deleteIcon: true,
						refreshCounts: refresh,
					})}
					onClick={() => scope.clickDelete(trees)}>
					<DeleteOutlineIcon />
				</DNBIconButton>
			)}
		</span>
	);
};

export {QueryBlock};
