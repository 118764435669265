import * as yup from 'yup';
import type {SchemaOf} from 'yup';
import type {
	InputConfig,
	InputRangeConfig,
	Vals,
	Val,
} from '../../../../query.types';

// #region Yup Schema InputConfig
const isValidRange = (inputConfig?: InputConfig, value?: number): boolean => {
	const isValidMin =
		inputConfig?.min && typeof value === 'number'
			? value >= parseFloat(inputConfig.min)
			: true;
	const isValidMax =
		inputConfig?.max && typeof value === 'number'
			? value <= parseFloat(inputConfig.max)
			: true;

	return isValidMin && isValidMax;
};

interface RangeSchema {
	showFrom?: boolean;
	from?: Val;
	showTo?: boolean;
	to?: Val;
}

const schema = (config?: InputRangeConfig): SchemaOf<RangeSchema> =>
	yup.object().shape({
		showFrom: yup.boolean(),
		from: yup.number().when('showFrom', {
			is: true,
			then: yup
				.number()
				.required()
				.test('range', 'value out of range', (value) =>
					isValidRange(config?.from, value)
				),
		}),
		showTo: yup.boolean(),
		to: yup.number().when('showTo', {
			is: true,
			then: yup
				.number()
				.required()
				.test('range', 'value out of range', (value) =>
					isValidRange(config?.to, value)
				),
		}),
	});

// #endregion Yup Schema InputConfig

interface IsFieldValidParams {
	key: keyof RangeSchema;
	rangeSchema: SchemaOf<RangeSchema>;
	rangeValue: RangeSchema;
}

const isFieldValid = ({
	key,
	rangeSchema,
	rangeValue,
}: IsFieldValidParams): boolean => {
	/**
	 * Try to execute the yup method validateSyncAt.
	 * If the function fails, yup throws an error.
	 * If it's valid, yup returns the current value.
	 *
	 * The function will fail if the above schema is not
	 * completed for the field.
	 */
	try {
		const fieldValue = rangeSchema.validateSyncAt(key, rangeValue);

		return typeof fieldValue === 'number' || typeof fieldValue === 'undefined';
	} catch (error) {
		// TODO: Should we display the error?
		return false;
	}
};

interface IsNumericalRangeValidParams {
	vals?: Vals;
	rangeConfig?: InputRangeConfig;
	showFrom?: boolean;
	showTo?: boolean;
}

const isNumericalRangeValid = ({
	vals,
	rangeConfig,
	showFrom,
	showTo,
}: IsNumericalRangeValidParams): boolean => {
	/**
	 * If there is no range config in the form
	 * that means that we are hiding the component.
	 * So we are safe to skip the validation.
	 */
	if (!rangeConfig || (!showFrom && !showTo)) {
		return true;
	}

	const [firstValue, secondValue] = vals || [];

	const config = {
		...rangeConfig,
		from: {
			...rangeConfig.from,
			max: rangeConfig.from.max || secondValue?.toString(),
		},
		to: {
			...rangeConfig.to,
			min: firstValue?.toString(),
		},
	};

	const isRange = showFrom && showTo;

	/**
	 * The existing forms manage the range values
	 * with an array, and if the 'from' input is hidden
	 * the toValue is push to the first place of the array.
	 * That is why if it is not a range we chose the
	 * first value.
	 */
	const toValue = showTo ? firstValue : undefined;
	const rangeToValue = isRange ? secondValue : toValue;

	return schema(config).isValidSync({
		showFrom,
		from: typeof firstValue === 'string' ? parseFloat(firstValue) : firstValue,
		showTo,
		to:
			typeof rangeToValue === 'string'
				? parseFloat(rangeToValue)
				: rangeToValue,
	});
};

export {schema, isFieldValid, isNumericalRangeValid};
