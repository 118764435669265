import {get} from 'lodash';
import {ImportAuthKey} from 'atlas/connectors/EIF/Data/Authentication/AuthenticationType';
import {SystemType} from 'atlas/connectors/EIF/Data/SystemType';
import {
	IFieldMappingsResponse,
	IGroupAttributesResponse,
	IMappedFields,
} from 'atlas/connectors/EIF/Data/API/BE/ActivationConnection';
import {
	SendAction,
	SendType,
} from 'atlas/playbook/content/liveramplaunch/types';
import {
	MandatoryDestinationFieldsMap,
	MandatorySourceFieldsMap,
	systemsWithNewMapping,
	systemsWithAudienceOperation,
	systemsWithSolutionInstanceIdAPI,
} from '../constants/fieldMapping.constants';
import type {CustomObjectDestinationField} from '../types/fieldMapping.types';
import type {
	CustomObjects,
	LegacyCustomObjects,
} from '../types/dataExtension.types';

export const getAuthId = (authId?: string): string => {
	const storageAuthKey = sessionStorage.getItem(ImportAuthKey);
	const storageAuthObject = storageAuthKey ? JSON.parse(storageAuthKey) : {};
	const storageAuthId = get(storageAuthObject, 'externalId');

	return authId ?? storageAuthId;
};

export const isSystemWithNewMapping = (system: SystemType): boolean =>
	systemsWithNewMapping.includes(system);

export const isSystemWithAudienceOperation = (system: SystemType): boolean =>
	systemsWithAudienceOperation.includes(system);

export const isSystemsWithSolutionInstanceIdAPI = (
	system: SystemType
): boolean => systemsWithSolutionInstanceIdAPI.includes(system);

export const hasAllMandatoryFields = (
	system: SystemType,
	mappedFields: IFieldMappingsResponse[]
): boolean => {
	const mandatoryDestinationFields = MandatoryDestinationFieldsMap.get(system);
	const mandatorySourceFields = MandatorySourceFieldsMap.get(system);

	if (mappedFields.length === 0) {
		return true;
	}

	if (mandatoryDestinationFields || mandatorySourceFields) {
		const hasAllDestinationFields = mandatoryDestinationFields
			? mandatoryDestinationFields.every((mandatoryField) =>
					mappedFields.some(({destinationField}) =>
						Array.isArray(mandatoryField)
							? mandatoryField.includes(destinationField)
							: destinationField === mandatoryField
					)
			  )
			: true;

		const hasAllSourceFields = mandatorySourceFields
			? mandatorySourceFields.every((mandatoryField) =>
					mappedFields.some(({sourceField}) => sourceField === mandatoryField)
			  )
			: true;

		return hasAllDestinationFields && hasAllSourceFields;
	}

	return true;
};

export const destinationFieldParser = (
	destinationField: string
): null | CustomObjectDestinationField => {
	try {
		return JSON.parse(destinationField);
	} catch {
		return null;
	}
};

export const parseAttributeDisplayName = (
	attribute: Partial<IGroupAttributesResponse>
): string =>
	`${attribute.Entity ?? ''}${attribute.Entity ? '.' : ''}${
		attribute.Category ? `${attribute.Category}.` : ''
	}${attribute.DisplayName ?? ''}`;

export const getSelectedDataExtensions = (
	mappedFields: IMappedFields,
	customObjects?: CustomObjects | LegacyCustomObjects
): CustomObjects => {
	if (!customObjects) {
		return [];
	}

	const mappedDestinations = Object.values(mappedFields).map(
		({fieldMapping: {destinationField}}) =>
			destinationFieldParser(destinationField)
	);

	return customObjects?.filter(({id}) =>
		mappedDestinations.some(
			(mappedDestination) =>
				mappedDestination?.customObjectId?.toString() === id.toString()
		)
	);
};

// #region getMandatoryDestinationFields
type GetMandatoryDestinationFieldsParams = {
	system: SystemType;
	audience?: SendType;
	operation?: SendAction;
};

export const getMandatoryDestinationFields = ({
	system,
	audience,
	operation,
}: GetMandatoryDestinationFieldsParams): (string | string[])[] | undefined =>
	MandatoryDestinationFieldsMap.get(
		`${system}${audience ? `_${audience}` : ''}${
			operation ? `_${operation}` : ''
		}`
	);
// #endregion getMandatoryDestinationFields

export const validateRequiredFields = (
	{system, audience, operation}: GetMandatoryDestinationFieldsParams,
	mappedDestinationFields: string[],
	destinationField: string
): boolean => {
	const requiredFields = getMandatoryDestinationFields({
		system,
		audience,
		operation,
	});
	return !!requiredFields?.some((item) => {
		// For required fields with 'or' condition
		if (Array.isArray(item) && item.includes(destinationField)) {
			return !item.find(
				(field) =>
					field !== destinationField && mappedDestinationFields.includes(field)
			);
		}

		return item === destinationField;
	});
};
