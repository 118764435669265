enum SystemType {
	Salesforce = 'Salesforce',
	Salesforce_Marketing_Cloud = 'SalesforceMarketingCloud',
	Eloqua = 'Eloqua',
	AWS_S3 = 'FlatFile',
	OneTime = 'OneTime',
	LinkedIn = 'LinkedIn',
	Facebook = 'Facebook',
	Marketo = 'Marketo',
	Pardot = 'Pardot',
	Outreach = 'Outreach',
	GoogleAds = 'GoogleAds',
	Google_Display_N_Video_360 = 'Google_Display_N_Video_360',
	AppNexus = 'Xandr_Invest',
	MediaMath = 'MediaMath',
	Adobe_Audience_Mgr = 'Adobe_Audience_Mgr',
	Verizon_Media = 'Verizon_Media',
	TradeDesk = 'TradeDesk',
	GenericDSP = 'GenericDsp',
	Microsoft_Dynamics = 'MicrosoftDynamics',
	Microsoft_Dynamics365 = 'Dynamic365',
	Hub_Spot = 'HubSpot',
	Sales_Loft = 'SalesLoft',
	/*
	PAY ATTENTION TO this system type.
	Custom is UI only system type, Used by Add Connection Page
	After user add a Custom connection, it popups S3/OneTime connection to be created.
	 */
	Custom = 'Custom',
	DataVision = 'DataVision',
	G2 = 'G2',
	TrustRadius = 'TrustRadius',
}

enum SystemLabelType {
	Auto = 'Auto',
	UI = 'UI',
	S3 = 'S3',
	Custom = 'Custom',
	File = 'File',
}

enum SystemCategory {
	SocialAds = 'Social Ads',
	DisplayAds = 'Display Ads',
	ProgrammaticAds = 'Programmatic Ads',
	SalesEnablement = 'CRM',
	MarketingAutomation = 'Marketing Automation',
	Custom = 'Custom',
	File = 'File',
	Intent = 'Intent',
	Other = 'Other',
}

const DefaultSystemName = 'Untitled Connection';
const DefaultCustomSystemName = 'Untitled Custom';
const MultipleMarketingActivitySystems = [
	SystemType.Marketo,
	SystemType.Eloqua,
];
const FileBasedSystemTypes = [SystemType.AWS_S3, SystemType.OneTime];

const ExternalAuthIdMap = new Map<Partial<SystemType>, string>([
	[
		SystemType.Salesforce_Marketing_Cloud,
		'external_salesforce_marketing_cloud_authentication',
	],
	[SystemType.Hub_Spot, 'external_hubspot_authentication'],
]);

const isConnectionUneditable = (systemType: SystemType): boolean =>
	systemType === SystemType.DataVision;

export {
	SystemType,
	SystemLabelType,
	SystemCategory,
	DefaultSystemName,
	DefaultCustomSystemName,
	MultipleMarketingActivitySystems,
	FileBasedSystemTypes,
	ExternalAuthIdMap,
	isConnectionUneditable,
};
