import {
	getSchemaData as getSchemaDataCall,
	setSchemaData as setSchemaDataCall,
} from './apiCalls';
import {
	deleteDataBegin,
	deleteDataEnd,
	fetchDataBegin,
	fetchDataEnd,
	setDrawerVisibility,
} from '../slices/schemaSlice';
import {setAttributesBegin, setAttributesEnd} from '../slices/attributeSlice';
import store from '../store';
import {CDP_SCHEMA_FIELD, schemaNamespace} from '../consts';
import type {
	IAttributeData,
	IAttributeUpdateData,
	ISchemaResponseData,
	IStoreState,
	TSchemaType,
} from '../schemaTypes';

interface SetSchemaDataErrorResponse {
	errorCode: string;
	errorMsg: string;
}

const defaultIgnored = (): boolean => false;

export function getSchemaData(
	schemaType: TSchemaType,
	ignored = defaultIgnored
): Promise<void> {
	store.dispatch(fetchDataBegin());
	return getSchemaDataCall(schemaType)
		.then((response: {data: ISchemaResponseData}) => {
			if (!ignored()) {
				store.dispatch(fetchDataEnd(response.data));
			}
		})
		.catch(() => {
			store.dispatch(fetchDataEnd(null));
		});
}

export function addSchemaData(
	schemaType: TSchemaType,
	updateData: Array<IAttributeUpdateData>
): Promise<SetSchemaDataErrorResponse | void> {
	store.dispatch(setAttributesBegin());
	const {
		[schemaNamespace]: {schemaData},
	} = store.getState();

	const requestData = {
		...schemaData,
		fieldDefinitionsRecordsMap: {
			...schemaData.fieldDefinitionsRecordsMap,
			[CDP_SCHEMA_FIELD]: [
				...(
					schemaData.fieldDefinitionsRecordsMap[CDP_SCHEMA_FIELD] ?? []
				).concat(updateData),
			],
		},
	};
	return setSchemaDataCall(requestData)
		.then(async () => {
			store.dispatch(setAttributesEnd());
			store.dispatch(setDrawerVisibility(false));
			// params in `schemaData` may change after save, so we must do fetch again
			await getSchemaData(schemaType);
		})
		.catch((e) => {
			store.dispatch(setAttributesEnd());
			return e.response.data as SetSchemaDataErrorResponse;
		});
}

export function editSchemaData(
	schemaType: TSchemaType,
	targetData: IAttributeUpdateData
): Promise<SetSchemaDataErrorResponse | void> {
	store.dispatch(setAttributesBegin());
	const {
		[schemaNamespace]: {schemaData},
	}: IStoreState = store.getState();
	// only one item in data when edit attirbute
	const targetField = targetData.property!;
	const targetAttributes = [
		...schemaData!.fieldDefinitionsRecordsMap[targetField]!,
	];
	targetAttributes[targetData!.propertyIndex!] = targetData;
	const requestData = {
		...schemaData!,
		fieldDefinitionsRecordsMap: {
			...schemaData!.fieldDefinitionsRecordsMap,
			[targetField]: targetAttributes,
		},
	};
	return setSchemaDataCall(requestData)
		.then(async () => {
			store.dispatch(setAttributesEnd());
			store.dispatch(setDrawerVisibility(false));
			// params in `schemaData` may change after save, so we must do fetch again
			await getSchemaData(schemaType);
		})
		.catch((e) => {
			store.dispatch(setAttributesEnd());
			return e.response.data as SetSchemaDataErrorResponse;
		});
}

export function deleteData(
	rows: IAttributeData[]
): Promise<SetSchemaDataErrorResponse | void> {
	store.dispatch(deleteDataBegin());
	rows.sort((prev, next) => next.propertyIndex - prev.propertyIndex);

	const {
		[schemaNamespace]: {schemaData},
	} = store.getState();

	const requestData = {
		...schemaData,
		fieldDefinitionsRecordsMap: {...schemaData.fieldDefinitionsRecordsMap},
	};

	rows.forEach(({property, propertyIndex}) => {
		requestData.fieldDefinitionsRecordsMap[property] = [
			...requestData.fieldDefinitionsRecordsMap[property],
		];
		requestData.fieldDefinitionsRecordsMap[property].splice(propertyIndex, 1);
	});

	return setSchemaDataCall(requestData)
		.then(() => {
			store.dispatch(deleteDataEnd(rows));
		})
		.catch((e) => e.response.data as SetSchemaDataErrorResponse);
}
