import messageService from 'common/app/utilities/message-service';
import Message, {NOTIFICATION, SUCCESS} from 'common/app/utilities/message';
import {
	FeatureFlags,
	isFeatureFlagEnabled,
	isSegmentationV2Enabled,
} from 'common/stores/tenantConfig';
import {getQueryProperty} from 'common/stores/query';
import {Restriction} from 'components/datacloud/query/query.types';
import {
	getAlertEmail,
	IAlertEmailConfig,
} from '../../../../../atlas/app/helpers/subscription.helpers';
import {sanitizeSegment} from '../../segment/segment.helpers';
import {Query} from '../../datacloud.types';

enum ViewType {
	Generic = 'generic',
	Account = 'accounts',
	Contact = 'contacts',
}

enum ExportType {
	None = 'NONE',
	Account = 'ACCOUNT',
	Contact = 'CONTACT',
	AccountAndContact = 'ACCOUNT_AND_CONTACT',
}

interface IExportParam {
	hasAccounts: boolean;
	hasContacts: boolean;
}

type IExportViewTypeDict = Record<
	ViewType,
	(param: IExportParam) => ExportType
>;

const ExportViewTypeDict: IExportViewTypeDict = {
	[ViewType.Account]: (): ExportType => ExportType.Account,
	[ViewType.Contact]: (): ExportType => ExportType.AccountAndContact,
	[ViewType.Generic]: ({
		hasAccounts,
		hasContacts,
	}: IExportParam): ExportType => {
		if (hasAccounts && hasContacts) {
			return ExportType.AccountAndContact;
		}
		if (hasAccounts) {
			return ExportType.Account;
		}
		if (hasContacts) {
			return ExportType.Contact;
		}
		return ExportType.None;
	},
};

const getSanitizedSegmentByConfig = (config?: Query): Query => {
	const accountRestriction =
		getQueryProperty<Restriction>('accountRestriction');
	const contactRestriction =
		getQueryProperty<Restriction>('contactRestriction');
	const memberRestriction = getQueryProperty<Restriction>('memberRestriction');

	const segmentCommonConfig: Query = {
		account_restriction: accountRestriction,
		contact_restriction: contactRestriction,
		restrict_without_sfdcid: false,
		...(isSegmentationV2Enabled()
			? {member_restriction: memberRestriction}
			: {}),
		materialized: false,
		name: '',
		display_name: '',
	};

	const segmentConfig = {
		...segmentCommonConfig,
		...config,
	};

	return sanitizeSegment(segmentConfig);
};

const showExportBanner = (): void => {
	const msgSendEmail =
		'Your export request was accepted. You will receive an email with a download link once it is complete.';
	const msgNoEmail =
		'Your export request was accepted. You can download it on the Jobs->Segment Export page.';
	const sendMessage = (msg: string): void =>
		messageService.sendMessage(
			new Message(null, NOTIFICATION, SUCCESS, msg, '')
		);
	const emailGroup = isFeatureFlagEnabled(FeatureFlags.EMAIL_GROUP);
	if (emailGroup) {
		getAlertEmail(true)
			.then((data: IAlertEmailConfig) => {
				if (data?.segmentation?.exportsRunning) {
					sendMessage(msgSendEmail);
				} else {
					sendMessage(msgNoEmail);
				}
			})
			.catch((error) => {
				console.error('Request to /pls/alertemail failed: ', error);
			});
	} else {
		sendMessage(msgSendEmail);
	}
};

export default getSanitizedSegmentByConfig;
export {ViewType, ExportType, ExportViewTypeDict, showExportBanner};
