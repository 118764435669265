import React from 'common/react-vendor';
import {useApi} from 'common/app/hooks/useApi';
import {NumericalRange} from '../../edit/NumericalRange/NumericalRange';
import {isNumericalRangeValid} from '../../edit/NumericalRange/numericalRange.validations';
import {numericTimeConfig} from '../transactionStore.constants';
import type {BucketRestriction} from '../../../../query.types';
import {
	TreeType,
	BucketCmp,
	TransactionKey,
	Period,
	BucketType,
} from '../../../../query.enums';
import {getPeriodDataList, getCompareList} from '../transactionStore.helpers';
import {
	hideTimeFrameCmps,
	hideNumericalPeriodFromCmps,
	numericalPeriodToCmps,
	timeFrameCmps,
} from './transactionEditItem.constants';
import {DateRange} from '../../edit/DateRange/DateRange';
import {
	changeValue,
	changeTimeframePeriod,
	getBucketCmp,
	resetBktValues,
} from '../../tree.helpers';
import {
	getTimeFramePeriod,
	updatePurchaseHistoryCmp,
	getTimeFrameConfig,
	getSubTypeConfig,
	getNumericalRangeValues,
} from './transactionEditItem.helpers';
import {SubType} from '../../tree.types';
import {NumericalRangeStatusesKeys} from './TransactionEditItem';
import {Val} from '../../../../query.types';

const {useState} = React;

interface TransactionTimeFrameProps {
	type?: TreeType;
	bucketRestriction?: BucketRestriction;
	onBucketRestrictionChange(newBucketRestriction?: BucketRestriction): void;
	onNumericalRangeValidation(
		key: NumericalRangeStatusesKeys,
		isValid: boolean
	): void;
}

const TransactionTimeFrame = ({
	type,
	bucketRestriction,
	onBucketRestrictionChange,
	onNumericalRangeValidation,
}: TransactionTimeFrameProps): React.ReactElement => {
	const {data: periodList} = useApi('getPeriodDataList', () =>
		getPeriodDataList()
	);

	const [numericalPeriodConfig, setNumericalPeriodConfig] = useState(
		getSubTypeConfig({
			bucketRestriction,
			type,
			subType: TransactionKey.Time,
			config: numericTimeConfig,
		})
	);

	const timeCmp = getBucketCmp({
		bucketRestriction,
		type,
		subType: TransactionKey.Time,
	});

	const timeFrameCmp = timeCmp === '' ? BucketCmp.EVER : timeCmp;

	const [cmpValue, setCmpValue] = useState<BucketCmp>(timeFrameCmp);
	const [timeFrameConfig, setTimeFrameConfig] = useState(
		getTimeFrameConfig({bucketRestriction, type, timeCmp})
	);
	const [timeFramePeriod, setTimeFramePeriod] = useState(
		getTimeFramePeriod({
			bucketRestriction,
			type,
			periodList,
		})
	);

	const showTimeFrame = timeFrameCmps.includes(timeFrameCmp as BucketCmp);

	const showTimeFramePeriod = !hideTimeFrameCmps.includes(
		timeFrameCmp as BucketCmp
	);

	const showFromPeriod = !hideNumericalPeriodFromCmps.includes(
		timeFrameCmp as BucketCmp
	);
	const showToPeriod = numericalPeriodToCmps.includes(
		timeFrameCmp as BucketCmp
	);

	const getNewBucketRestriction = (
		value: BucketCmp
	): BucketRestriction | undefined => {
		const bucketParserParams = {
			bucketRestriction,
			type,
			value,
			subType: TransactionKey.Time as SubType,
		};

		return resetBktValues({
			...bucketParserParams,
			bucketRestriction: updatePurchaseHistoryCmp(bucketParserParams),
		});
	};

	const handleTimeFramePeriodChange = (newTimeFramePeriod: string): void => {
		onBucketRestrictionChange(
			changeTimeframePeriod({
				bucketRestriction,
				type,
				value: newTimeFramePeriod as Period,
			})
		);

		setTimeFramePeriod(newTimeFramePeriod);
	};

	const resetRangeValues = (): void => {
		setNumericalPeriodConfig((previousState) => ({
			...previousState,
			from: {
				...previousState.from,
				value: undefined,
			},
			to: {
				...previousState.to,
				value: undefined,
			},
		}));
	};

	const setDefaultNumericalPeriodValues = (
		newCmp: BucketCmp,
		newBucketRestriction: BucketRestriction | undefined
	): void => {
		const newCmpShowFrom = !hideNumericalPeriodFromCmps.includes(newCmp);
		const newCmpShowTo = numericalPeriodToCmps.includes(newCmp);

		onNumericalRangeValidation('isTimeFrameValid', true);

		/**
		 * If we are not showing the NumericalRange
		 * then skip the set, and set numerical valid
		 * as true.
		 */
		if (!newCmpShowFrom && !newCmpShowTo) {
			resetRangeValues();
			return;
		}

		const newFromValue = newCmpShowFrom ? 1 : undefined;

		const showToValue = newCmpShowTo ? 1 : undefined;
		const newToValue = newCmpShowFrom && newCmpShowTo ? 2 : showToValue;

		changeValue({
			bucketRestriction: newBucketRestriction,
			type,
			value: newFromValue,
			index: 0,
			subType: TransactionKey.Time,
		});

		changeValue({
			bucketRestriction: newBucketRestriction,
			type,
			value: newToValue,
			index: 1,
			subType: TransactionKey.Time,
		});

		setNumericalPeriodConfig((previousState) => ({
			...previousState,
			from: {
				...previousState.from,
				value: newFromValue,
				initial: newFromValue?.toString(),
			},
			to: {
				...previousState.to,
				value: newToValue,
				initial: newToValue?.toString(),
			},
		}));
	};

	const handleTimeFrameCmpChange = (newCmp: BucketCmp): void => {
		const newBucketRestriction = getNewBucketRestriction(newCmp);

		setDefaultNumericalPeriodValues(newCmp, newBucketRestriction);

		onBucketRestrictionChange(newBucketRestriction);

		const isTimeFrameHidden = hideTimeFrameCmps.includes(newCmp);

		if (newCmp === BucketCmp.EVER) {
			handleTimeFramePeriodChange(Period.Week);
		} else if (isTimeFrameHidden) {
			handleTimeFramePeriodChange(BucketType.Date);
		} else {
			const [firstPeriodOption = {name: ''}] = periodList || [];

			handleTimeFramePeriodChange(firstPeriodOption.name);
		}

		setCmpValue(newCmp);

		setTimeFrameConfig(
			getTimeFrameConfig({
				bucketRestriction: newBucketRestriction,
				type,
				timeCmp: newCmp,
			})
		);
	};

	const handleNumericalRangeChange = (
		index: number,
		subType?: string,
		value?: Val
	): void => {
		changeValue({
			bucketRestriction,
			type,
			value,
			index,
			subType: subType as SubType,
		});

		const newNumericalConfig = getSubTypeConfig({
			bucketRestriction,
			type,
			subType: TransactionKey.Time,
			config: numericTimeConfig,
		});

		onNumericalRangeValidation(
			'isTimeFrameValid',
			isNumericalRangeValid({
				vals: getNumericalRangeValues({
					bucketRestriction,
					type,
					subType: TransactionKey.Time,
				}),
				rangeConfig: newNumericalConfig,
				showFrom: showFromPeriod,
				showTo: showToPeriod,
			})
		);

		setNumericalPeriodConfig(newNumericalConfig);
	};

	const handleDateRangeChange = (
		index: number,
		subType?: string,
		value?: Val
	): void => {
		changeValue({
			bucketRestriction,
			type,
			value,
			index,
			subType: subType as SubType,
		});
	};

	return (
		<div className='query-section-item-operation transaction-row'>
			<div className='transaction-element'>
				<div className='flex-container'>
					<div className='flex-container time-frame'>
						<div className='transaction-element-title'>in timeframe</div>

						<select
							value={cmpValue}
							onChange={({target: {value}}) =>
								handleTimeFrameCmpChange(value as BucketCmp)
							}
							className='time-frame-select'>
							{getCompareList().map(({name, displayName}) => (
								<option key={name} value={name}>
									{displayName}
								</option>
							))}
						</select>
					</div>

					{showTimeFrame && (
						<DateRange
							config={timeFrameConfig}
							showFrom={timeFrameConfig.from.visible}
							showTo={timeFrameConfig.to.visible}
							bucketRestriction={bucketRestriction}
							onChange={({type, index, value}) =>
								handleDateRangeChange(index, type, value)
							}
						/>
					)}
				</div>
			</div>

			{(showFromPeriod || showToPeriod) && showTimeFramePeriod && (
				<div className='transaction-element'>
					<NumericalRange
						config={numericalPeriodConfig}
						showMessage
						showFrom={showFromPeriod}
						showTo={showToPeriod}
						bucketRestriction={bucketRestriction}
						onChange={({type, index, value}) =>
							handleNumericalRangeChange(index, type, value)
						}
					/>
				</div>
			)}

			{showTimeFramePeriod && (
				<div className='transaction-element'>
					<select
						value={timeFramePeriod}
						onChange={({target: {value}}) =>
							handleTimeFramePeriodChange(value)
						}>
						{periodList?.map(({name, displayName}) => (
							<option key={name} value={name}>
								{displayName}
							</option>
						))}
					</select>
				</div>
			)}
		</div>
	);
};

export {TransactionTimeFrame};
